import React, { useState, useContext } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Server } from "../../utils/server";
import { Languages } from "../../lang/translation";
import { ProfileContext } from "../../contexts/LangContext";
import { Modal, message } from "antd";
import axios from "axios";
import SecureLS from "secure-ls";
import SliderCaptcha from "../../components/SliderCaptcha";
import * as Yup from "yup";
import { useFormik } from "formik";

import "./style.css";
import clasess from "./classes.module.css";
import { Registration } from "./Registration";
import LoginUser from "./LoginUser";

var ls = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: "-*follow-the-technology-profile*-",
});

try {
  ls.get("-secure-token-");
} catch (err) {
  console.log("err token");
  ls.removeAll();
}
export const SignUp = ({ visible, Setvisible }) => {
  const [loginn, setlogin] = useState("signin");
  const { language } = useContext(ProfileContext);

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [pass_1, setpass_1] = useState("");
  const [pass_2, setpass_2] = useState("");
  const [parol, setparol] = useState("");
  const [phone, setphone] = useState("");
  const [code, setcode] = useState("");
  const [captcha_token, setCaptcha_token] = useState("");
  const [resetToken, setResetToken] = useState("");

  const [noaccount, setNoaccount] = useState(false);
  const [phoneError, setphoneError] = useState(true);
  const [load, setload] = useState(false);

  const handleValidation = () => {
    let formIsValid = true;
    // console.log(name + " - " + email);
    // email
    if (email) {
      // formIsValid = false;
      // message.error(Languages[language].emailempty);
      // } else {
      let lastAtPos = email.lastIndexOf("@");
      let lastDotPos = email.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        message.error(Languages[language].emailvalid);
      }
    }
    //name
    if (!name) {
      formIsValid = false;
      message.error(Languages[language].nameempty);
    } else {
      if (name.length < 3) {
        formIsValid = false;
        message.error(Languages[language].nameless);
      }
    }
    //password
    if (!pass_1) {
      formIsValid = false;
      message.error(Languages[language].passwordempty);
    } else {
      if (pass_1.length < 4) {
        formIsValid = false;
        message.error(Languages[language].passwordless);
      }
    }

    if (!pass_2) {
      formIsValid = false;
      message.error(Languages[language].passwordconfirm);
    }

    if (pass_1 !== pass_2) {
      formIsValid = false;
      message.error(Languages[language].passwordnotequal);
    }

    //phone

    if (!phone) {
      formIsValid = false;
      message.error(Languages[language].phoneempty);
    }

    return formIsValid;
  };

  const handleValidationpass = () => {
    let formIsValid = true;

    //password
    if (!pass_1) {
      formIsValid = false;
      message.error(Languages[language].passwordempty);
    } else {
      if (pass_1.length < 4) {
        formIsValid = false;
        message.error(Languages[language].passwordless);
      }
    }

    if (!pass_2) {
      formIsValid = false;
      message.error(Languages[language].passwordconfirm);
    }

    if (pass_1 !== pass_2) {
      formIsValid = false;
      message.error(Languages[language].passwordnotequal);
    }

    return formIsValid;
  };

  const handleSubmitpass = () => {
    if (handleValidationpass()) {
      const formData = new FormData();
      formData.append("_pass", pass_1);
      formData.append("_u_phone_1", phone);
      axios
        .post(Server() + "/api/user_updatepass", formData)
        .then((response) => {
          setpass_1("");
          setpass_2("");
          if (response.data.update) {
            message.success(Languages[language].updatepasstxt);
          } else {
            message.warn(Languages[language].errors);
          }
          setlogin("passsuccess");
          setNoaccount(false);
        })
        .catch(function (err) {
          console.log(err);
          message.error(Languages[language].errors);
        });
    }
  };

  const handleSubmit = () => {
    if (handleValidation()) {
      const formData = new FormData();
      formData.append("_u_name", name);
      formData.append("_u_password", pass_1);
      formData.append("_u_email", email);
      formData.append("_u_type", 1);
      formData.append("_u_phone_1", phone);
      axios
        .post(Server() + "/api/users_add", formData)
        .then((response) => {
          setname("");
          setphone("");
          setpass_1("");
          setpass_2("");
          setNoaccount(false);
          message.success(Languages[language].success);
          ls.set("-secure-token-", JSON.stringify(response.data));
          setlogin("success");
        })
        .catch(function (err) {
          console.log(err);
          message.error(Languages[language].errors);
        });
    }
  };

  const Val = (e) => {
    if (e[0] > 0 && e[0] < 6 && e.length === 7) {
      setphoneError(false);
    } else {
      setphoneError(true);
    }
    setphone(e);
  };

  const Verfi_submit = () => {
    setload(true);
    const formData = new FormData();
    formData.append("tel", phone);

    axios
      .post(Server() + "/api/otp_add", formData)
      .then((response) => {
        setload(false);
        setcode("");
        if (response.data.block) {
          message.warn(Languages[language].bann);
        } else if (response.data.reg) {
          message.warn(Languages[language].registered);
          setlogin("password");
        } else {
          message.success(Languages[language].waiting2) && setlogin("confirm");
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    setNoaccount(false);
  };

  const Reset_submit = () => {
    if (!captcha_token) {
      message.error(Languages[language]["verifyYouAreHuman"]);
      return;
    }
    setload(true);
    const formData = new FormData();
    formData.append("tel", phone);
    formData.append("key", captcha_token);
    axios
      .post(Server() + "/api/reset_add", formData)
      .then((response) => {
        if (response.data.block) {
          message.warn(Languages[language].bann);
        } else if (response.data.regno) {
          message.warn(Languages[language].noregistered + "!");
          setNoaccount(true);
          setlogin("signup");
          // setphone("");
        } else {
          setResetToken(response.data.token);
          message.success(Languages[language].waiting2);
          setlogin("resetconfirm");
        }
        setload(false);
        setCaptcha_token("");
      })
      .catch(function (error) {
        console.log(error);
        setCaptcha_token("");
      });
  };

  const Confirm_code = () => {
    setload(true);
    const formData = new FormData();
    formData.append("tel", phone);
    formData.append("code", code);
    axios
      .post(Server() + "/api/otp_confirm", formData)
      .then((response) => {
        response.data.confirm
          ? message.success(Languages[language].confirmsuccess) &&
            setlogin("info")
          : message.error(Languages[language].errcode);
        setNoaccount(false);
        setload(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const Confirm_resetcode = () => {
    setload(true);
    const formData = new FormData();
    formData.append("tel", phone);
    formData.append("code", code);
    formData.append("token", resetToken);
    axios
      .post(Server() + "/api/reset_confirm", formData)
      .then((response) => {
        response.data.confirm
          ? message.success(Languages[language].confirmsuccess) &&
            setlogin("newpass")
          : message.error(Languages[language].errcode);
        setload(false);
        setNoaccount(false);
        setResetToken("");
      })
      .catch(function (error) {
        console.log(error);
        setResetToken("");
      });
  };

  const Login_User = () => {
    setload(true);
    const formData = new FormData();
    formData.append("tel", phone);
    formData.append("parol", parol);
    axios
      .post(Server() + "/api/signin", formData)
      .then((response) => {
        if (response.data.login) {
          message.success(Languages[language].login);
          ls.set("-secure-token-", JSON.stringify(response.data));
          Setvisible(false);
        } else {
          message.warn(Languages[language].errpass);
        }
        setNoaccount(false);
        setload(false);
      })
      .catch(function (error) {
        message.error(Languages[language].errors);
      });
  };

  const CheckUserNumber = () => {
    setload(true);
    const formData = new FormData();
    formData.append("tel", phone);
    axios
      .post(Server() + "/api/checknumber", formData)
      .then((response) => {
        if (response.data.bann) {
          message.warn(Languages[language].bann);
        } else if (response.data.bar && !response.data.bann) {
          setlogin("password");
        } else {
          message.warn(Languages[language].noregistered);
          setNoaccount(true);
          setlogin("signup");
          // setphone("");
        }
        setload(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GoLogin_User = (event) => {
    if (event.key === "Enter") {
      Login_User();
    }
  };

  const GoVerfi_submit = (event) => {
    if (event.key === "Enter") {
      Verfi_submit();
    }
  };

  const GoConfirm_code = (event) => {
    if (event.key === "Enter") {
      Confirm_code();
      // Timer();
    }
  };
  const GoConfirm_resetcode = (event) => {
    if (event.key === "Enter") {
      Confirm_resetcode();
    }
  };
  return (
    <Modal
      visible={visible}
      onCancel={Setvisible}
      maskClosable={false}
      footer={null}
      closable={false}
      style={{ top: "20px" }}
      width={375}
      height={800}
      className="Modal-login"
      zIndex={1009}
    >
      {/* ____________________________________________________________________________________ */}

      {/* Sign up  */}
      {loginn === "signup" ? (
        <Registration
          load={load}
          Setvisible={Setvisible}
          setload={setload}
          ls={ls}
          Languages={Languages}
          language={language}
          setlogin={setlogin}
          setNoaccount={setNoaccount}
          noaccount={noaccount}
          phoneError={phoneError}
        />
      ) : // /* ____________________________________________________________________________________ */}
      // confirm

      // moved by Bezirgen

      // {/* ____________________________________________________________________________________ */}
      // Reste Confirm
      loginn === "resetconfirm" ? (
        <>
          <div className="login-title">{Languages[language].tassykla}</div>
          <div className="login-close" onClick={Setvisible}>
            <PlusOutlined className="login-close-icon" />
          </div>
          <p className="login-name">{Languages[language].code} </p>
          <div className="login-input">
            {Languages[language].kod}:
            <input
              autoFocus
              type="text"
              value={code}
              style={{ textAlign: "center" }}
              className="login-input-text-pass"
              onChange={(e) => setcode(e.target.value)}
              onKeyDown={GoConfirm_resetcode}
            />
          </div>
          <input
            style={load ? { marginTop: 10, opacity: 0.6 } : { marginTop: 10 }}
            className="c-orderbutton"
            type="button"
            value={
              load ? Languages[language].waiting : Languages[language].sendenter
            }
            disabled={load}
            // onClick={() => Timer()}
            onClick={() => Confirm_resetcode()}
          />
        </>
      ) : // {/* ____________________________________________________________________________________ */}
      loginn === "info" ? (
        <>
          <div className="login-title">{Languages[language].signup}</div>
          <div className="login-close" onClick={Setvisible}>
            <PlusOutlined className="login-close-icon" />
          </div>

          <p className="login-name">{Languages[language].info}</p>
          <div className="login-input">
            {Languages[language].name}:
            <input
              autoFocus
              type="text"
              onChange={(text) => setname(text.target.value)}
              className="login-input-text-pass"
              value={name}
            />
          </div>

          <div className="login-input">
            {Languages[language].password}:
            <input
              type="password"
              style={{ outline: "none", border: 0 }}
              className="login-input-text-pass"
              value={pass_1}
              onChange={(text) => setpass_1(text.target.value)}
            />
          </div>

          <div className="login-input">
            {Languages[language].confirm}:
            <input
              type="password"
              style={{ outline: "none", border: 0 }}
              className="login-input-text-pass"
              value={pass_2}
              onChange={(text) => setpass_2(text.target.value)}
            />
          </div>

          <div className="login-input">
            {Languages[language].emailreg}:
            <input
              type="text"
              className="login-input-text-pass"
              value={email}
              onChange={(text) => setemail(text.target.value)}
            />
          </div>
          <input
            style={{ position: "relative", top: 12 }}
            className="c-orderbutton"
            type="button"
            value={Languages[language].save}
            onClick={() => handleSubmit()}
          />
        </>
      ) : // {/* ____________________________________________________________________________________ */}
      loginn === "newpass" ? (
        <>
          <div className="login-title">{Languages[language].updatepass}</div>
          <div className="login-close" onClick={Setvisible}>
            <PlusOutlined className="login-close-icon" />
          </div>

          <p className="login-name">{Languages[language].newpass}</p>

          <div className="login-input">
            {Languages[language].password}:
            <input
              type="password"
              style={{ outline: "none", border: 0 }}
              className="login-input-text-pass"
              value={pass_1}
              onChange={(text) => setpass_1(text.target.value)}
            />
          </div>

          <div className="login-input">
            {Languages[language].confirm}:
            <input
              type="password"
              style={{ outline: "none", border: 0 }}
              className="login-input-text-pass"
              value={pass_2}
              onChange={(text) => setpass_2(text.target.value)}
            />
          </div>
          <input
            style={{ position: "relative", top: 12 }}
            className="c-orderbutton"
            type="button"
            value={Languages[language].resetbtn}
            onClick={() => handleSubmitpass()}
          />
        </>
      ) : // {/* ____________________________________________________________________________________ */}
      loginn === "success" ? (
        <>
          <div className="login-title">{Languages[language].thanks}!</div>
          <div className="login-close" onClick={Setvisible}>
            <PlusOutlined className="login-close-icon" />
          </div>
          <p className="login-name">{Languages[language].regcomp}!</p>
          <div className="completed">
            <div className="completed-icon" />
          </div>
          <input
            style={{ position: "relative", top: 12 }}
            className="c-orderbutton"
            type="button"
            value={Languages[language].okey}
            onClick={Setvisible}
          />
        </>
      ) : loginn === "passsuccess" ? (
        <>
          <div className="login-title">{Languages[language].thanks}!</div>
          <div className="login-close" onClick={Setvisible}>
            <PlusOutlined className="login-close-icon" />
          </div>
          <p className="login-name">{Languages[language].editcomp}!</p>
          <div className="completed">
            <div className="completed-icon" />
          </div>
          <input
            style={{ position: "relative", top: 12 }}
            className="c-orderbutton"
            type="button"
            value={Languages[language].signin}
            onClick={() => setlogin("signin")}
          />
        </>
      ) : // {/* ____________________________________________________________________________________ */}
      loginn === "signin" ? (
        <LoginUser
          Languages={Languages}
          language={language}
          Setvisible={Setvisible}
          ls={ls}
          setNoaccount={setNoaccount}
          load={load}
          setload={setload}
          setlogin={setlogin}
        />
      ) : // : // {/* _______________________________Signin____password_________________________________________________ */}
      //   loginn === "password" ? (
      //     <>
      //       <div className="login-title">{Languages[language].welcome}</div>
      //       <div className="login-close" onClick={Setvisible}>
      //         <PlusOutlined className="login-close-icon" />
      //       </div>
      //       <p className="login-name">{Languages[language].passwordtext}</p>

      //       <div className="login-input">
      //         {Languages[language].pass}:
      //         <input
      //           type="password"
      //           style={{ outline: "none", border: 0 }}
      //           className="login-input-text-pass"
      //           value={parol}
      //           onChange={(text) => setparol(text.target.value)}
      //           onKeyDown={GoLogin_User}
      //         />
      //       </div>
      //       <input
      //         style={{ marginTop: 10 }}
      //         className="c-orderbutton"
      //         type="button"
      //         value={Languages[language].enter}
      //         disabled={phoneError}
      //         onClick={() => Login_User()}
      //       />
      //     </>
      //   )
      // {/* ____________________________________________________________________________________ */}
      loginn === "reset" ? (
        // Reset your password
        <>
          <div className="login-title">{Languages[language].resetpass}? </div>
          <div className="login-close" onClick={Setvisible}>
            <PlusOutlined className="login-close-icon" />
          </div>
          <p className="login-name">{Languages[language].resettext}</p>
          <div
            className="login-input"
            style={phoneError ? { borderColor: "red" } : null}
          >
            <div className="belgi-name">{Languages[language].phonenumber}</div>
            <b className="belgi">+993 6</b>
            <input
              autoFocus
              type="number"
              className="login-input-text-number"
              value={phone}
              style={{
                border: 1,
                width: "75%",
                fontSize: 18,
                color: "#000",
                fontWeight: "bold",
              }}
              onChange={(text) => Val(text.target.value)}
              // onKeyDown={GoVerfi_submit}
            />
          </div>
          <SliderCaptcha
            create={`${Server()}/api/captcha`}
            verify={`${Server()}/api/captcha`}
            callback={(token) => setCaptcha_token(token)}
            text={{
              anchor: Languages[language]["iAmNotRobot"],
              challenge: Languages[language]["slideToFinish"],
            }}
            isVerified={!!captcha_token}
          />
          <input
            style={
              phoneError
                ? { marginTop: 10, opacity: 0.6 }
                : load
                ? { marginTop: 10, opacity: 0.6 }
                : { marginTop: 10 }
            }
            className="c-orderbutton"
            type="button"
            value={
              load ? Languages[language].wait : Languages[language].sendenter
            }
            disabled={load ? load : phoneError}
            onClick={() => Reset_submit()}
          />
        </>
      ) : null}
      {/* _______________________________Sign in_____________________________________________________ */}
      {loginn === "signin" ? (
        // <div className="login-footer">
        //   {Languages[language].member}?{" "}
        //   <b
        //     onClick={() => setlogin("signup")}
        //     style={{ cursor: "pointer", userSelect: "none" }}
        //   >
        //     {Languages[language].signup}
        //   </b>
        // </div>

        <div className="login-footer">
          {Languages[language].resetpass}?&nbsp;&nbsp;&nbsp;
          <b
            onClick={() => setlogin("reset")}
            style={{ cursor: "pointer", userSelect: "none" }}
          >
            {Languages[language].reset}
          </b>
        </div>
      ) : loginn === "password" ? (
        <div className="login-footer">
          {Languages[language].resetpass}?&nbsp;&nbsp;&nbsp;
          <b
            onClick={() => setlogin("reset")}
            style={{ cursor: "pointer", userSelect: "none" }}
          >
            {Languages[language].reset}
          </b>
        </div>
      ) : null}
    </Modal>
  );
};

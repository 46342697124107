import React from "react";
import logo from "../../img/gyrat-logo.png";
import Google from "./img/googleplay.png";
import Appstore from "./img/appstore.png";
const DownloadApp = () => {
  return (
    <div
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginTop: 20,
        width: "100%",
        flexDirection: "column",
      }}
    >
      <div>
        <img src={logo} style={{ width: 250 }} />
      </div>
      <div style={{ marginTop: 40 }}>
        <a
          href="https://apps.apple.com/us/app/gyrat/id1563218520"
          target="_blank"
        >
          <img src={Appstore} style={{ width: 200, cursor: "pointer" }} />
        </a>
      </div>
      <div style={{ marginTop: 20 }}>
        <a
          href="https://play.google.com/store/apps/details?id=com.gyratcomputer"
          target="_blank"
        >
          <img src={Google} style={{ width: 200, cursor: "pointer" }} />
        </a>
      </div>
      <div>
        <a href="/download_app/gyrat.apk" download target="_blank">
          <button
            style={{
              flex: 1,
              height: 50,
              backgroundColor: "#010101",
              color: "#fff",
              width: 200,
              alignItems: "center",
              marginTop: 20,
              outline: "none",
              border: 0,
              borderRadius: 8,
              fontSize: 18,
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            Скачать APK
          </button>
        </a>
      </div>
    </div>
  );
};

export default DownloadApp;

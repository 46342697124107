import React, { createContext, useReducer, useEffect, useState } from "react";
import { CartReducer, sumItems } from "./cartReducer";

import SecureLS from "secure-ls";
var ls = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: "-*follow-the-technology-cart*-",
});

export const CartContext = createContext();

try {
  ls.get("-secure-cart-");
} catch (err) {
  console.log("err");
  ls.removeAll();
}
const storage = ls.get("-secure-cart-")
  ? JSON.parse(ls.get("-secure-cart-"))
  : [];

let initialState = {
  cartItems: storage,
  ...sumItems(storage),
  checkout: false,
  isChecked: true,
};

const CartContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CartReducer, initialState);

  const cardChecked = (payload) => {
    dispatch({ type: "CARD_CHECKED", payload });
  };

  const increase = (payload) => {
    dispatch({ type: "INCREASE", payload });
  };

  const decrease = (payload) => {
    dispatch({ type: "DECREASE", payload });
  };

  const addProduct = (payload) => {
    dispatch({ type: "ADD_ITEM", payload });
  };

  const removeProduct = (payload) => {
    dispatch({ type: "REMOVE_ITEM", payload });
  };

  const UpdateCart = (payload) => {
    dispatch({ type: "UPDATE", payload });
  };

  const UpdateAllCart = (payload) => {
    dispatch({ type: "UPDATEALL", payload });
  };

  const clearCart = () => {
    dispatch({ type: "CLEAR" });
  };

  const handleCheckout = () => {
    dispatch({ type: "CHECKOUT" });
  };

  const contextValues = {
    // added by bezirgen
    cardChecked,
    //
    removeProduct,
    addProduct,
    increase,
    decrease,
    clearCart,
    handleCheckout,
    UpdateCart,
    UpdateAllCart,
    ...state,
  };

  return (
    <CartContext.Provider value={contextValues}>
      {children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;

export const Languages = {
  tm: {
    // added by Bezirgen
    notRegisteredYet: "Entäk registrasiýa geçmediňizmi?",
    confirmLogout: "Siz hakykatdanam çykmak isleýärsiňizmi?",
    errpassorphone: "Açar sözüňiz ýa-da telefon nomeriňiz nädogry",
    errorCanceling:
      "Operatorlar siziň sargydyňyz kabul etdi. Siz sargydyňyzy goýbolsun edip bilmeýärsiňiz",
    encreaseError: "Siz mundan köp alyp bilmeýärsiňiz. Bu önüm çäklendirilen",
    confirmCancellingOrder:
      "Size has gowy hyzmat berip bilmek üçin goýbolsun etmegiňiziň sebäbini görkezmegiňizi haýyş edýäris",
    today: "Şu gün",
    tomorrow: "Ertir",
    timeForDelivery: "Eltip bermeli wagty saýlaň",
    timeForDeliveryError:
      "Sargydyň wagtyny saýlamak mümkinçiligini alyp bolmady.",
    filledDeliveryTime: "Saýlanan wagt aralygynda sargyt sany doldy",
    expiredDeliveryTime: "Saýlanan sagat aralygynda sargyt edip bolmaýar.",
    limitFull: "Bagyşlaň sargyt çäklendirmesi doldy",
    freeDeliveryLimit: "Mugt eltip bermek üçin sargydyňyzyň mukdary ",
    freeDeliveryLimitAddition: " manatdan köp bolmaly.",
    paymentDeliveryService: "Eltip bermek hyzmaty ",
    sumWithDelivery: "Eltip bermek hyzmaty bilen: ",
    deliveryLimits:
      "Eltip bermek uçin gerek bolan çäklendirmeleri alyp bolmady",
    productPriceerror: "Şu harytlary sargamak üçin söwdaňyz ",
    productPriceerrorAddition: " manady geçmeli",
    orderCancel: "Ýok",
    orderOk: "Ugrat",
    addAddressInRegistrationMoreDetails: "Salgy barada has giňişleýin: ",
    fillFields: "Meýdany doldurmagyňyzy haýyş edýäris",
    registrationSuccess: "Siz üstünlikli hasaba alyndyňyz",
    somethingWentWrong:
      "Bir zat nädogry boldy. Biraz wagtdan gaýtadan synanyşyň",

    selectReasonForCancelling: "Sargydy goýbolsun edilmeginiň sebäbini ýazyň",
    reasonForCancelling: "Sargydy goýbolsun edilmeginiň sebäbi",
    reasonForCancelling1: "Täzeden sargyt etmekçi",
    reasonForCancelling2: "Bahalaryňyz gymmat.",
    reasonForCancelling3: "Görkezilen salgymda bolamok",
    reasonForCancelling4: "Başga sebäpler boýunça",
    express: "Express",
    expressDeliveryDesc:
      "Siz dogurdan hem Express eltip bermek hyzmaty saýlaýarsyňyzmy?",
    expressDeliveryConfirmYes: "Hawa",
    expressDeliveryConfirmNo: "Ýok",
    expressDeliveryCost: "Express eltip bermek hyzmatynyň bahasy ",
    sumWithExpressDelivery: "Express eltip bermek hyzmaty bilen: ",
    expressDeliveryTime1: "Sargydyňyz ",
    expressDeliveryTime2: " minudyň dowamynda gowşurylar.",
    expressDisable: "Express eltip bermek hyzmaty elýeterli däl",
    verifyYouAreHuman: '"Men robot däl" belgisini tassyklaň!',
    iAmNotRobot: "Men robot däl",
    slideToFinish: "Tassyklamak üçin nyşany süýşüriň",

    //
    contact: "Habarlaşmak",
    fave: "Halananlar",
    profile: "Profil",
    addcart: "Sebede goş",
    new: "Täze",
    premium: "Premium",
    withpremium: "Premium bilen",
    catalog: "Kategoriýalar",
    instock: "stokda bar",
    notinstock: "elimizde ýok",
    remained: "galdy",
    stock: "Kody ",
    home: "Baş sahypa",
    addresssaved: "Salgylarym",
    name: "Adyňyz",
    phone: "Telefon",
    email: "E-poçta",
    lang: "Saýtyň dili",
    changepass: "Paroly çalyşmak",
    mycart: "Sebedim",
    orderhistory: "Sargytlarym",
    cartitem: "Sebetdäki harytlar",
    ordermessage:
      "Спасибо за покупку! Оператор свяжется с вами для подтверждения заказа!",
    cartempty: "Siziň sebediňiz boş. Sebediňize haryt goşuň!",
    delivery: "Eltip bermek we tölemek",
    payment: "Töleg gornüşi",
    cash: "Nagt",
    bank: "Töleg terminaly",
    address: "Salgy",
    addaddress: "Salgyňyzy goşuň",
    yukle: "Ýüklemek",
    download: "Mobil programmany ýüklemek",
    notes: "Bellik",
    order: "Sargyt etmek",
    aboutorder: "Sargyt etmek",
    totalitems: "Jemi",
    totalorder: "Jemi baha",
    clear: "Pozmak",
    srch: "Gözleg",
    items: "Harytlar",
    price: "Bahasy",
    qty: "Sany",
    total: "Jemi",
    status: "Ýagdaýy",
    ordernum: "Sargyt belgisi",
    date: "Senesi",
    pending: "Garaşylýar",
    accepted: "Kabul edildi",
    ondeliver: "Ugradyldy",
    complated: "Tamamlandy",
    cancelled: "Goýbolsun",
    emptysaved: "Siziň halan harydyňyz ýok",
    returning: "Harytlary yzyna tabşyrmak",
    sale: "Arzanlaşyk",
    brands: "Markalar",
    other: "Beýlekiler",
    filters: "Süzgüçler",
    allprice: "Hödürlenen tertipde",
    minmax: "Arzandan gymmada",
    maxmin: "Gymmatdan arzana",
    all: "Hemmesi",
    local: "Ýerli",
    foreign: "Daşary",
    nfound: "Siziň gözleýän harydyňyz tapylmady",
    result: "Jemi",
    footertxt: "Elektron söwda dükany",
    footertxt2: "müşderi hyzmaty",
    aboutus: "Biz barada",
    work: "Biz nähili işleýäris",
    faq: "Sorag-Jogap",
    partnership: "Hyzmatdaşlyk",
    jobs: "Iş orunlary",
    contactus: "Habarlaşmak",
    help: "Kömek",
    support: "Kömek we goldaw",
    emailus: "E-poçtalarymyz",
    hotline: "Telefon belgimiz",
    apps: "Telefon üçin programalar",
    seeall: "Hemmesini görmek",
    cancel: "Goýbolsun",
    save: "Ýatda sakla",
    empty: "Boş",
    smart: "Hödürlenen kategoriýalar",
    productnew: "Täze harytlar",
    productsale: "Arzanladyşdaky harytlar",
    view: "Ählisi",
    more: "Dowamy",
    emailvalid: "E-poçta dogry däl!",
    numbervalid: "Telefon belgiňiz dogry däl!",
    emailempty: "E-poçtaňyzy ýazyň!",
    nameempty: "Adyňyzy ýazyň!",
    nameless: "Adyňyzy örän gysga!",
    nameletters: "Adyňyzy harplar bilen ýazyň!",
    passwordempty: "Parolyňyzy giriziň!",
    passwordless: "Parolyňyz örän gysga!",
    passwordconfirm: "Parolyňyzy tassyklaň!",
    passwordnotequal: "Üns beriň, parolyňyz gabat gelenok!",
    phoneempty: "Telefon belgiňizi giriziň!",
    messageemty: "Hatyňyzy ýazyň!",
    errors: "Ýalňyşlyk, gaýtadan synanyşyň!",
    login: "Üstünlikli içeri girdiňiz!",
    welcome: "Hoş geldiňiz",
    signup: "Agza bolmak",
    signuptext: "Agza bolmak üçin telefon belgiňizi ýazyň",
    signin: "Girmek",
    signintext: "Telefon belgiňizi ýazyň",
    phonenumber: "Telefon",
    passwordtext: "Açar sözüňizi ýazyň",
    wait: "Garaşyň...",
    send: "Ugratmak",
    sendcode: "Registrasiýa",
    code: "Size SMS kod ugradyldy, telefon belgiňize gelen kody ýazyň",
    kod: "Kod",
    info: "Adyňyz we açar sözüňizi ýazyň",
    password: "Täze açar söz",
    confirm: "Täze açar söz (gaýtadan)",
    emailreg: "Elektron poçta (boş goýup bilersiňiz)",
    thanks: "Sag boluň",
    regcomp: "Siz üstünlikli agza bolduňuz",
    editcomp: "Siz üstünlikli açar sözüňizi çalyşdyňyz",
    pass: "Açar söz",
    member: "Öň agza bolanlar üçin",
    registered: "Siz öň registrasiýa bolupsyňyz!",
    reset: "Çalyşmak",
    resetpass: "Açar sözi unutduňyzmy",
    updatepass: "Açar sözi çalyşmak",
    resettext: "Telefon belgiňizi ýazyň",
    noregistered: "Siz hasaba alnan ulanyjy däl",
    newpass: "Täze açar sözüňizi ýazyň",
    updatepasstxt: "Açar sözüňizi üstünlikli çalyşdyňyz!",
    errpass: "Açar sözüňiz nädogry",
    confirmsuccess: "Nomeriňizi üstünlikli tassykladyňyz!",
    guarantee: "Kepillik",
    sendSuccess: "Üstünlikli ugradyldy! Biziň habarymyza garaşyň!",
    sharecopy: "Harydyň nusgasyny paýlaşyp bilersiňiz",
    confirmbtn: "Tassyklamak",
    waiting: "Garaşyň...",
    waiting2: "Garaşmagyňyzy haýyş edýäris!",
    update: "Üstünlikli üýtgeldildi!",
    success: "Üstünlikli ýerne ýetirildi!",
    messagecart:
      "Stok ýagdaýymyza görä sebediňizdäki şu haryda üýtgeşme girizildi!",
    updatecart: "Stok ýagdaýymyza görä sebediňizdäki üýtgeşme girizildi!",
    msg: "Hatyňyz",
    brand_cat: "Kategoriýa boýunça",
    logout: "Çykmak",
    enter: "Girmek",
    sendenter: "Tassyklamak",
    bann: "Size girmek üçin rugsat berlenok!",
    tassykla: "Tassyklamak",
    okey: "Bolýar",
    noaccount:
      "Telefon belgiňiz tapylmady. Agza bolmak üçin telefon belgiňizi ýazyň",
    errcode: "Tassyklama koduňyz nädogry",
    resetbtn: "Saklamak",
    notfound: "Sahypa tapylmady",
    notfoundtxt:
      "Gözleýän sahypaňyz ýok, üýtgedilen ýa-da wagtlaýyn elýeterli däl",
    back: "Yza",
    man: "manat",
    uns: "Üns beriň",
    privacy: "Ulanyş düzgünleri",
    rules: "Ulanyş düzgünleri",
    privacy_policy: "Gizlinlik ýörelgesi",
    errortxt:
      "Ýalňyşlyk! Käbir maglumatlar ýüklenmedi. Sahypany täzelemgiňizi haýyş edýäris.",
    reload: "Täzele",
    notify_title: "Hormatly müşderiler!",
    notify_text:
      "Tehniki näsazlyklar sebäpli 13-nji we 14-nji tapgyrdan galan salgylara eltip bermek hyzmatymyz saklanyldy. Biz sargytlaryňyzy taýýar edip bileris. Sargytlaryňyzy biziň dükanymyzdan gelip alyp bilersiňiz. Salgy: Oguzhan 118, (Aýtakow, Bagtyýarlyk sowda merkezini geçenden soň 2nji jaý.) Tel: 72-43-11",
  },
  en: {
    // added by Bezirgen
    notRegisteredYet: "Not registered yet",
    confirmLogout: "Do you really want to logout?",
    errpassorphone: "Your password or phone number is incorrect",
    errorCanceling:
      "The operators accepted your order. You cannot cancel your order",
    encreaseError: "You can't get much more. This product is limited",
    confirmCancellingOrder:
      "Please provide the reason for the cancellation for better service.",
    today: "Today",
    tomorrow: "Tomorrow",
    timeForDelivery: "Select when to deliver",
    timeForDeliveryError: "Unable to select order time.",
    filledDeliveryTime:
      "The number of orders has been completed in the selected time period",
    expiredDeliveryTime: "Cannot be ordered within the selected hour.",
    limitFull: "Sorry, the order limit is full",
    freeDeliveryLimit:
      "For free delivery, the amount of your order must be more than ",
    freeDeliveryLimitAddition: " manats.",
    paymentDeliveryService: "Delivery service ",
    sumWithDelivery: "Delivery amount: ",
    deliveryLimits:
      "The restrictions required for delivery could not be obtained",
    productPriceerror: "Your order must exceed ",
    productPriceerrorAddition: " manat to order these goods",
    orderCancel: "Cancel",
    orderOk: "Send",
    addAddressInRegistrationMoreDetails: "More about the address:",
    fillFields: "Please fill in the field",
    registrationSuccess: "You have successfully registered",
    somethingWentWrong: "Something went wrong. Please try again later",
    verifyYouAreHuman: 'Confirm "I\'m not a robot"!',
    iAmNotRobot: "I am not a robot",
    slideToFinish: "To confirm, slide the element",

    selectReasonForCancelling:
      "Write down the reason why the order was canceled.",
    reasonForCancelling: "The reason for the cancellation of the order.",
    reasonForCancelling1: "I want to order again.",
    reasonForCancelling2: "Your prices are expensive.",
    reasonForCancelling3: "I am not at the specified address.",
    reasonForCancelling4: "For other reasons.",
    express: "Express",
    expressDeliveryDesc: "Do you really need express delivery?",
    expressDeliveryConfirmYes: "Yes",
    expressDeliveryConfirmNo: "No",
    expressDeliveryCost: "The cost of the express delivery service is ",
    sumWithExpressDelivery: "Amount with express delivery: ",
    expressDeliveryTime1: "Your order will be delivered within ",
    expressDeliveryTime2: " minutes.",
    expressDisable: "Express delivery service is not available",

    //
    contact: "Contacts",
    fave: "Saved",
    profile: "Profile",
    addcart: "Add to cart",
    new: "New",
    premium: "Premium",
    withpremium: "with Premium",
    catalog: "Categoies",
    instock: "in stock",
    remained: "remained",
    stock: "Stock",
    notinstock: "not in stock",
    home: "Home",
    other: "Other",
    addresssaved: "Saved address",
    download: "Download mobile app",
    yukle: "Download",
    returning: "Returning order",
    name: "Name",
    phone: "Phone",
    email: "E-mail",
    lang: "Language",
    changepass: "Change password",
    mycart: "My cart",
    orderhistory: "Order history",
    cartitem: "Cart Items",
    ordermessage:
      "Thanks for your order! Operator will contact to confirm your order!",
    cartempty: "Your cart is empty. Add goods to your basket!",
    delivery: "Delivery & Payment",
    payment: "Payment",
    cash: "Cash",
    bank: "Payment terminal",
    address: "Address",
    addaddress: "Add address",
    notes: "Notes",
    order: "Order",
    aboutorder: "About order",
    totalitems: "Total items",
    totalorder: "Total order",
    clear: "Clear",
    srch: "Search",
    items: "Items",
    price: "Price",
    qty: "Qty",
    total: "Total",
    status: "Status",
    errpass: "Your password is incorrect",
    ordernum: "Order number",
    date: "Date",
    pending: "Pending",
    accepted: "Accepted",
    ondeliver: "On delivery",
    complated: "Complated",
    cancelled: "Cancelled",
    emptysaved: "Empty saved",
    sale: "Sale",
    brands: "Brands",
    filters: "Filters",
    allprice: "All sorts of",
    minmax: "Price low to hight",
    maxmin: "Price hight to low",
    all: "All",
    local: "Local",
    foreign: "Foreign",
    nfound: "No results were found for your search.",
    result: "Result",
    footertxt: "E-commerce sore with excellent",
    footertxt2: "customer service",
    aboutus: "About us",
    work: "How it works",
    faq: "F.A.Q",
    partnership: "Cooporation",
    jobs: "Jobs",
    contactus: "Contact us",
    help: "Help",
    support: "Help & Support",
    emailus: "E-mail us",
    hotline: "Hotline",
    apps: "Mobile applications",
    seeall: "See all",
    cancel: "Cancel",
    enter: "Enter",
    empty: "Empty",
    smart: "Smart Categories",
    productnew: "New Products",
    productsale: "Discounted products",
    view: "View all",
    more: "Show more",
    emailvalid: "Email is not valid!",
    numbervalid: "Phone number is not valid!",
    emailempty: "Email is empty!",
    nameempty: "Write your name!",
    nameless: "Your name is too short!",
    nameletters: "Write your name in letters!",
    passwordempty: "Enter your password!",
    passwordless: "Your password is too short!",
    passwordconfirm: "Confirm your password!",
    passwordnotequal: "Your password does not match!",
    phoneempty: "Enter your phone number",
    messageemty: "Enter your phone message!",
    errors: "Error, please try again!",
    login: "Login successfully!",
    welcome: "Welcome to 1OOharyt",
    signin: "Sign in",
    signintext: "Please enter your number to sign in",
    signup: "Sign up",
    signuptext:
      "Please enter your phone number. We will send you confirmation SMS",
    phonenumber: "Phone",
    passwordtext: "Please enter your password",
    wait: "Wait...",
    send: "Send",
    sendcode: "Registration",
    code: "Please wait for the SMS with the confirmation code. And enter the recieved code",
    kod: "Code",
    info: "Please fill your info",
    password: "New Password",
    confirm: "Confirm Password",
    emailreg: "E-mail (Optional, you can leave it empty)",
    thanks: "Thank you",
    regcomp: "You have successfully registered to gyratcomputer.com",
    editcomp: "You have successfully changed your password",
    pass: "Password",
    member: "Already a member",
    registered: "You have already registered!",
    reset: "Reset",
    resetpass: "Forgot password",
    resettext: "Please enter your number reset your password",
    noregistered: "You are not a registered user",
    updatepass: "Change password",
    newpass: "Please enter new password",
    save: "Save",
    updatepass: "You have successfully changed your password!",
    confirmsuccess: "You have successfully verified your number!",
    guarantee: "Guarantee",
    sendSuccess: "Successfully sent! Wait for our message!",
    sharecopy: "You can share the product copy",
    waiting: "Loading...",
    waiting2: "Please wait!",
    confirmbtn: "Confirm",
    update: "Updated successfully!",
    success: "Successful!",
    messagecart:
      "We have made changes to this product depending on our situation in the warehouse!",
    updatecart:
      "We have made changes depending on our situation in the warehouse!",
    msg: "Your letter",
    brand_cat: "By category",
    logout: "Logout",
    sendenter: "Enter",
    tassykla: "Confirmation",
    okey: "Okey",
    bann: "You are not allowed to access!",
    noaccount: "Account not found. Please press enter to register",
    errcode: "Invalid confirmation code",
    resetbtn: "Save",
    notfound: "Page not found",
    notfoundtxt:
      "Page that you are looking for might been removed, changed or temporarly unavailable",
    back: "Back",
    man: "manat",
    privacy: "Privacy and rules",
    rules: "Terms of Use",
    privacy_policy: "Pivacy policy",
    uns: "Attention",
    reload: "Reload",
    errortxt:
      "Error! Some information was not uploaded. Please refresh the page. ",
    notify_title: "Dear customers!",
    notify_text:
      "Temporarily we are unable to deliver your orders except for 13-14th district. After you've ordered we can prepare your orders to self pickup. Address: Oguzhan 118 (Aytakov, 2nd building after Bagtyyarlyk shopping center) Phone: 72-43-11",
  },
  ru: {
    // added by Bezirbgen
    notRegisteredYet: "Еще не зарегистрированы?",
    confirmLogout: "Вы действительно хотите выйти?",
    errpassorphone: "Ваш пароль или номер телефона неверны",
    errorCanceling:
      "Операторы приняли ваш заказ. Вы не можете отменить свой заказ",
    encreaseError: "Вы не можете купить больше. Этот товар лимитирован",
    confirmCancellingOrder:
      "Пожалуйста, укажите причину отмены для лучшего обслуживания.",
    today: "Cегодня",
    tomorrow: "Завтра",
    timeForDelivery: "Выберите время доставки",
    timeForDeliveryError: "Невозможно выбрать время заказа.",
    filledDeliveryTime:
      "Количество заказов было выполнено за выбранный период времени.",
    expiredDeliveryTime: "Нельзя заказать в течение выбранного часа.",
    limitFull: "Извините, лимит заказа заполнен",
    freeDeliveryLimit:
      "Для бесплатной доставки сумма вашего заказа должна быть больше ",
    freeDeliveryLimitAddition: " манат.",
    paymentDeliveryService: "Услуга доставки ",
    sumWithDelivery: "Сумма с доставкой: ",
    deliveryLimits: "Не удалось получить необходимые для доставки ограничения",
    productPriceerror:
      "Для заказа данного товара сумма вашего заказа должна превышать ",
    productPriceerrorAddition: " манат.",
    orderCancel: "Отмена",
    orderOk: "Отправить",
    addAddressInRegistrationMoreDetails: "Подробнее об адресе:",
    fillFields: "Пожалуйста, заполните поле",
    registrationSuccess: "Вы успешно зарегистрировались",
    somethingWentWrong:
      "Что-то пошло не так. Пожалуйста, повторите попытку позже",
    verifyYouAreHuman: "Подтвердите «Я не робот»!",
    iAmNotRobot: "Я не робот",
    slideToFinish: "Смахните для подтверждения",

    selectReasonForCancelling: "Запишите причину отмены заказа.",
    reasonForCancelling: "Причина отмены заказа.",
    reasonForCancelling1: "Я хочу заказать заново.",
    reasonForCancelling2: "Ваши цены дорогие.",
    reasonForCancelling3: "Я не живу по указанному адресу ",
    reasonForCancelling4: "По другим причинам.",
    express: "Экпресс",
    expressDeliveryDesc: "Вы действительно хотите экспресс доставку?",
    expressDeliveryConfirmYes: "Да",
    expressDeliveryConfirmNo: "Нет",
    expressDeliveryCost: "Стоимость услуги экспресс-доставки ",
    sumWithExpressDelivery: "Сумма с экспресс-доставкой: ",
    expressDeliveryTime1: "Ваш заказ будет доставлен в течение ",
    expressDeliveryTime2: " минут.",
    expressDisable: "Услуга экспресс-доставки недоступна",

    //
    contact: "Контакты",
    fave: "Отложенные",
    addcart: "В корзину",
    new: "Новинка",
    premium: "Premium",
    withpremium: "c Premium",
    catalog: "Каталог товаров",
    instock: "в наличии",
    returning: "Возврат товара",
    remained: "остался",
    stock: "Артикул",
    notinstock: "нет в наличии",
    home: "Главная Страница",
    profile: "Профиль",
    addresssaved: "Адреса",
    yukle: "Скачать",
    download: "Скачать мобильное приложение",
    name: "Имя",
    phone: "Телефон",
    email: "Эл. почта",
    lang: "Язык",
    changepass: "Сменить пароль",
    mycart: "Моя корзина",
    orderhistory: "История заказов",
    cartitem: "Товары в корзине",
    ordermessage:
      "Спасибо за покупку! Оператор свяжется с вами для подтверждения заказа!",
    cartempty: "Ваша корзина пуста. Добавляйте товары в корзину!",
    delivery: "Доставка и оплата",
    payment: "Оплата",
    cash: "Наличными",
    bank: "Банковская карта",
    address: "Адрес",
    addaddress: "Добавить адрес",
    notes: "Заметка",
    order: "Заказать",
    errpass: "Ваш пароль неверный",
    aboutorder: "О заказах",
    totalitems: "Всего товаров",
    totalorder: "Общий заказ",
    clear: "Очистить",
    srch: "Поиск",
    items: "Товары",
    price: "Цена",
    qty: "Кол-во",
    total: "Общее количество",
    status: "Статус",
    ordernum: "Номер заказа",
    date: "Дата",
    pending: "В ожидании",
    accepted: "Принято",
    ondeliver: "Доставляется",
    complated: "Завершено",
    cancelled: "Отменено",
    emptysaved: "Ничего не найдено",
    sale: "Скидки",
    brands: "Бренды",
    other: "Другие",
    filters: "Фильтры",
    allprice: "По умолчанию",
    minmax: "По возрастанию цены",
    maxmin: "По убыванию цены",
    all: "Все",
    local: "Местный",
    foreign: "Иностранный",
    nfound: "По Вашему запросу ничего не найдено",
    result: "Результат",
    footertxt: "Интернет-магазин с отличным",
    footertxt2: "обслуживание клиентов",
    aboutus: "О нас",
    work: "Как это работает",
    faq: "Вопросы-Ответы",
    partnership: "Сотрудничество",
    jobs: "Вакансии",
    contactus: "Связаться с нами",
    help: "Помощь",
    support: "Помощь и поддержка",
    emailus: "Эл. адрес",
    hotline: "Горячая линия",
    apps: "Мобильные приложения",
    seeall: "Увидеть все",
    cancel: "Отмена",
    save: "Сохранить",
    empty: "Пустой",
    smart: "Смарт категория",
    productnew: "Новые продукты",
    productsale: "Товары со скидкой",
    view: "Все",
    more: "Еще",
    emailvalid: "Проверьте почтовый адрес!",
    numbervalid: "Номер телефона недействителен!",
    emailempty: "Напишите свой Эл. почту!",
    nameempty: "Напишите свое имя!",
    nameless: "Ваше имя очень короткое!",
    nameletters: "Напишите свое имя буквами!",
    passwordempty: "Введите ваш пароль!",
    passwordless: "Ваш пароль слишком короткий!",
    passwordconfirm: "Подтвердите ваш пароль!",
    passwordnotequal: "Ваш пароль не совпадает",
    phoneempty: "Введите свой номер телефона",
    messageemty: "Введите текст!",
    errors: "Ошибка, попробуйте еще раз!",
    login: "Вход успешный",
    welcome: "Добро пожаловать",
    signin: "Войти",
    signintext: "Пожалуйста введите номер телефона",
    signup: "Регистрация",
    passwordtext: "Пожалуйста введите пароль",
    signuptext: "Пожалуйста, введите свой номер телефона",
    phonenumber: "Телефон",
    wait: "Подождите...",
    send: "Отправить",
    sendcode: "Зарегистрироваться",
    code: "Пожалуйста дождитесь SMS с кодом подтверждения. И введите полученный код",
    kod: "Код",
    info: "Пожалуйста, введите имя и новый пароль",
    password: "Новый пароль",
    confirm: "Новый пароль (повторно)",
    emailreg: "Электронная почта (можно оставить пустым)",
    thanks: "Спасибо",
    regcomp: "Вы успешно зарегистрировались на gyratcomputer.com",
    editcomp: "Вы успешно изменили ваш пароль",
    pass: "Пароль",
    member: "Уже зарегистрирован",
    registered: "Вы уже зарегистрировались!",
    noregistered: "Вы не зарегистрированный пользователь",
    reset: "Восстановить",
    resetpass: "Забыли пароль",
    resettext: "Пожалуйста введите номер телефона",
    updatepass: "Изменить пароль",
    resetbtn: "Изменить",
    newpass: "Пожалуйста, введите новый пароль",
    sendSuccess: "Успешно отправлено! Ждите нашего сообщения!",
    updatepasstxt: "Вы успешно сменили пароль!",
    confirmsuccess: "Вы успешно подтвердили свой номер!",
    guarantee: "Гарантия",
    sharecopy: "Вы можете поделиться копией товара",
    confirmbtn: "Подтвердить",
    waiting: "Подождите...",
    waiting2: "Пожалуйста, подождите!",
    update: "Успешно Обновлено!",
    success: "Успешно!",
    messagecart:
      "Мы вносили изменения в этот товар в зависимости от ситуации на складе!",
    updatecart: "Мы вносили изменения зависимости от ситуации на складе",
    msg: "Ваше письмо",
    brand_cat: "По категориям",
    logout: "Выйти",
    enter: "Войти",
    sendenter: "Подтвердить",
    tassykla: "Подтверждение",
    okey: "Ок",
    bann: "У вас нет доступа!",
    noaccount: "Номер не найден. Пожалуйста, зарегистрируйтесь",
    errcode: "Неверный код подтверждения!",
    notfound: "Страница не найдена",
    notfoundtxt:
      "Страница, которую вы ищете, могла быть удалена, изменен или временно недоступен",
    back: "Назад",
    man: "манат",
    privacy: "Условия Обслуживания",
    rules: "Правила пользования",
    uns: "Внимание",
    errortxt:
      "Ошибка! Некоторая информация не была загружена. Обновите страницу.",
    privacy_policy: "Конфиденциальность",
    reload: "Перезагрузить",
    notify_title: "Уважаемые покупатели!",
    notify_text:
      "По техническим причинам, кроме 13-14 этапов, мы не можем доставить ваши заказы. После того, как вы сделаете заказ, мы можем подготовить заказ для самовывоза. Адрес: ул.Огузхан 118 (Айтаков, 2-й дом после ТЦ Багтыярлык) Тел: 72-43-11",
  },
};

import { useQuery } from "react-query";
import { axiosInstance } from "../utils/server";

const getSmallBanners = async () => {
  const { data, error } = await axiosInstance.get("/api/small_list");
  return data;
};

export default function useBanners() {
  return useQuery("SmallBanners", getSmallBanners);
}

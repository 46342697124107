import SecureLS from "secure-ls";

var ls = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: "-*follow-the-technology-cart*-",
});
const Storage = async (cartItems) => {
  await ls.set(
    "-secure-cart-",
    JSON.stringify(cartItems.length > 0 ? cartItems : [])
  );
};

export const sumItems = (cartItems) => {
  Storage(cartItems);
  let itemCount = cartItems.reduce(
    (total, product) => (product.quantity ? total + product.quantity : total),
    0
  );

  let total = cartItems.reduce(
    (total, product) =>
      product.sale === "1" && product.quantity
        ? total + product.p_price_3 * product.quantity * product.product_unit
        : product.quantity
        ? total + product.p_price_1 * product.quantity * product.product_unit
        : total,
    0
  );

  return { itemCount, total };
};

export const CartReducer = (state, action) => {
  switch (action.type) {
    // added by Bezirgen
    case "CARD_CHECKED": {
      return {
        ...state,
        isChecked: action.payload,
      };
    }
    //
    case "ADD_ITEM":
      if (!state.cartItems.find((item) => item.p_id === action.payload.p_id)) {
        state.cartItems.push({
          ...action.payload,
          quantity: 1,
        });
      }

      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };
    case "REMOVE_ITEM":
      return {
        ...state,
        ...sumItems(
          state.cartItems.filter((item) => item.p_id !== action.payload.p_id)
        ),
        cartItems: [
          ...state.cartItems.filter(
            (item) => item.p_id !== action.payload.p_id
          ),
        ],
      };
    case "INCREASE":
      state.cartItems[
        state.cartItems.findIndex((item) => item.p_id === action.payload.p_id)
      ].quantity += 1;

      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };
    case "DECREASE":
      state.cartItems[
        state.cartItems.findIndex((item) => item.p_id === action.payload.p_id)
      ].quantity--;

      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };

    case "UPDATE":
      if (action.payload.product_qty && action.payload.product_qty === 1) {
        state.cartItems[
          state.cartItems.findIndex((item) => item.p_id === action.payload.p_id)
        ].quantity = 1;
      } else if (
        state.cartItems[
          state.cartItems.findIndex((item) => item.p_id === action.payload.p_id)
        ].quantity > action.payload.p_quantity
      ) {
        state.cartItems[
          state.cartItems.findIndex((item) => item.p_id === action.payload.p_id)
        ].quantity = action.payload.p_quantity;
      }

      if (
        state.cartItems[
          state.cartItems.findIndex((item) => item.p_id === action.payload.p_id)
        ].quantity > action.payload.product_limit &&
        action.payload.product_limit !== null &&
        action.payload.product_limit > 0 &&
        action.payload.isLimit === "1"
      ) {
        state.cartItems[
          state.cartItems.findIndex((item) => item.p_id === action.payload.p_id)
        ].quantity = action.payload.product_limit;
      }

      state.cartItems[
        state.cartItems.findIndex((item) => item.p_id === action.payload.p_id)
      ].p_quantity = action.payload.p_quantity;

      state.cartItems[
        state.cartItems.findIndex((item) => item.p_id === action.payload.p_id)
      ].product_unit = action.payload.product_unit;

      state.cartItems[
        state.cartItems.findIndex((item) => item.p_id === action.payload.p_id)
      ].p_price_1 = action.payload.p_price_1;

      state.cartItems[
        state.cartItems.findIndex((item) => item.p_id === action.payload.p_id)
      ].p_price_3 = action.payload.p_price_3;

      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };

    case "UPDATEALL":
      if (action.payload.product_qty && action.payload.product_qty === 1) {
        state.cartItems[
          state.cartItems.findIndex((item) => item.p_id === action.payload.p_id)
        ].quantity = 1;
      }

      state.cartItems[
        state.cartItems.findIndex((item) => item.p_id === action.payload.p_id)
      ].p_quantity = action.payload.p_quantity;

      state.cartItems[
        state.cartItems.findIndex((item) => item.p_id === action.payload.p_id)
      ].product_unit = action.payload.product_unit;

      state.cartItems[
        state.cartItems.findIndex((item) => item.p_id === action.payload.p_id)
      ].p_price_1 = action.payload.p_price_1;

      state.cartItems[
        state.cartItems.findIndex((item) => item.p_id === action.payload.p_id)
      ].p_price_3 = action.payload.p_price_3;

      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };

    case "CHECKOUT":
      return {
        cartItems: [],
        checkout: true,
        ...sumItems([]),
      };
    case "CLEAR":
      return {
        cartItems: [],
        ...sumItems([]),
      };
    default:
      return state;
  }
};

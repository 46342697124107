import React from 'react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Anchor from './anchor';
import Theme from './theme';

const fetchCaptcha = (create) => () =>
  create instanceof Function
    ? create() // Use provided promise for getting background and slider
    : fetch(create, {
      // Use create as API URL for fetch
      method: 'GET',
      // credentials: 'include',
    }).then((message) => message?.json());

const fetchVerification = (verify) => (response, trail, key) =>
  verify instanceof Function
    ? verify(response, trail, key) // Use provided promise for verifying captcha
    : fetch(verify, {
      // Verification API URL provided instead
      method: 'POST',
      // credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        response,
        trail,
        key,
      }),
    }).then((message) => message.json());

const SliderCaptcha = ({ callback, create, verify, variant, text, isVerified }) => {
  const [verified, setVerified] = useState(isVerified);
  const submitResponse = (response, trail, key) =>
    new Promise((resolve) => {
      fetchVerification(verify)(response, trail, key).then((verification) => {
        if (
          !verification.result ||
          verification.result !== 'success' ||
          !verification.token
        ) {
          resolve(false);
        } else {
          setTimeout(() => {
            callback(verification.token);
            setVerified(true);
          }, 500);
          resolve(true);
        }
      });
    });

  useEffect(() => {
    setVerified(isVerified);
  }, [isVerified])

  useEffect(() => {
    console.log(create);
  }, []);
  return (
    <div className="scaptcha-container">
      <Theme variant={variant} />
      <Anchor
        text={text}
        fetchCaptcha={fetchCaptcha(create)}
        submitResponse={submitResponse}
        verified={verified}
      />
    </div>
  );
};

SliderCaptcha.propTypes = {
  callback: PropTypes.func,
  create: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  verify: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  variant: PropTypes.string,
  text: PropTypes.shape({
    anchor: PropTypes.string,
    challenge: PropTypes.string,
  }),
  isVerified: PropTypes.bool
};

SliderCaptcha.defaultProps = {
  callback: (token) => console.log(token), // eslint-disable-line no-console
  create: 'captcha/create',
  verify: 'captcha/verify',
  variant: 'light',
  text: {
    anchor: 'I am human',
    challenge: 'Slide to finish the puzzle',
  },
  isVerified: false
};

export default SliderCaptcha;

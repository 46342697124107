import React, { useContext } from "react";
import { Drawer, Row, Col, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { CartContext } from "../../contexts/CartContext";
import { ProfileContext } from "../../contexts/LangContext";
import { isLogin } from "../../utils";
import { Server } from "../../utils/server";
import { Languages } from "../../lang/translation";
import Placeholderimg from "../../img/placeholder.png";
import Plus from "../../img/icons/plus.svg";
import Minus from "../../img/icons/minus.svg";
import ImageWebp from "../../components/ImageWebp";
import "./style.css";
import { checkMayIncrease } from "../card/card";
export const CartDrawer = ({ visible, onClose, setLogin }) => {
	const {
		cartItems,
		increase,
		decrease,
		removeProduct,
		total,
		itemCount,
		clearCart,
	} = useContext(CartContext);
	const { language } = useContext(ProfileContext);
	const Sign = () => {
		setLogin(true);
	};
	return (
		<>
			<Drawer
				mask={true}
				title={<b style={{ fontSize: 18 }}>{Languages[language].cartitem}</b>}
				maskClosable={true}
				width={340}
				headerStyle={{ minWidth: 340 }}
				placement="right"
				onClose={onClose}
				closable={true}
				visible={visible}
				zIndex={1008}
				footer={
					<div
						style={{
							textAlign: "center",
						}}
					>
						{isLogin() ? (
							<Link to="/cart">
								<input
									className="c-orderbutton"
									type="button"
									value={
										Number(total) === total && total % 1 !== 0
											? Languages[language].order +
											" ( " +
											Number(total).toFixed(2) +
											" " +
											Languages[language].man +
											" )"
											: Languages[language].order +
											" ( " +
											total +
											" " +
											Languages[language].man +
											" )"
									}
									onClick={onClose}
								/>
							</Link>
						) : (
							<input
								className="c-orderbutton"
								type="button"
								value={
									Number(total) === total && total % 1 !== 0
										? Languages[language].order +
										" ( " +
										Number(total).toFixed(2) +
										" " +
										Languages[language].man +
										" )"
										: Languages[language].order +
										" ( " +
										total +
										" " +
										Languages[language].man +
										" )"
								}
								onClick={Sign}
							/>
						)}
					</div>
				}
			>
				<div style={{ marginTop: -18 }}>
					{cartItems.length > 0 ? (
						<>
							{cartItems.map((product, index) => {
								return (
									<Row
										key={index}
										justify="space-between"
										className="drawer-cart-items"
									>
										<Col>
											<Link
												to={
													"/product/" + product.categoryKId + "/" + product.p_id
												}
											>
												<ImageWebp
													style={{ float: "left" }}
													alt="product"
													className="drawer-cart-product-img"
													srcWebp={Server() + "/img/products/" + product.p_img}
													src={
														product.p_img
															? Server() +
															"/img/productsj/" +
															product.p_img.substr(
																0,
																product.p_img.lastIndexOf(".")
															) +
															".jpg"
															: Placeholderimg
													}
													onError={(e) => {
														e.target.src = Placeholderimg;
													}}
												/>
											</Link>
										</Col>
										<Col span={9}>
											<div className="drawer-cart-bottom">
												<Link
													to={
														"/product/" +
														product.categoryKId +
														"/" +
														product.p_id
													}
												>
													<p className="drawer-cart-name">
														{language === "en"
															? product.p_name_en
															: language === "ru"
																? product.p_name_ru
																: language === "tm"
																	? product.p_name_tm
																	: product.p_name_en}
													</p>
												</Link>

												{product.sale && product.sale === "1" ? (
													<p className="drawer-cart-price header-manat">
														{(product.p_price_3 * product.product_unit) % 1 !==
															0
															? Number(
																product.p_price_3 * product.product_unit
															).toFixed(2)
															: product.p_price_3 * product.product_unit}
														{" " + Languages[language].man}
													</p>
												) : (
													<p className="drawer-cart-price header-manat">
														{(product.p_price_1 * product.product_unit) % 1 !==
															0
															? Number(
																product.p_price_1 * product.product_unit
															).toFixed(2)
															: product.p_price_1 * product.product_unit}
														{" " + Languages[language].man}
													</p>
												)}
											</div>
										</Col>
										<Col span={7}>
											<div className="drawer-cart-count">
												{product.quantity > 0 && (
													<button
														onClick={() => { product.quantity > 1 && decrease(product) }}
														className="drawer-cart-minus"
														disabled={
															Number(product.quantity) <= 0 ? true : false
														}
													>
														<img
															src={Minus}
															alt="-"
															style={{
																height: 14,
																width: 14,
																marginLeft: 2,
																marginTop: -2,
															}}
														/>
													</button>
												)}
												{Number(product.quantity) === 0 && (
													<button
														onClick={() => removeProduct(product)}
														className="drawer-cart-minus"
														disabled={
															Number(product.quantity) <= 0 ? true : false
														}
													>
														<img
															src={Minus}
															alt="del"
															style={{
																height: 14,
																width: 14,
																marginLeft: 2,
																marginTop: -2,
															}}
														/>
													</button>
												)}
												<p className="drawer-cart-counter">
													{product.quantity}
												</p>

												<button
													onClick={() => {
														increase(product)
														// if (checkMayIncrease(product, cartItems)) {

														// increase(product)
														// } else {
														//   message.error(Languages[language].encreaseError, 3)
														// }

													}}
													className="drawer-cart-plus"
												>
													<img
														src={Plus}
														alt="+"
														style={{
															height: 14,
															width: 14,
															marginTop: -4,
															marginRight: 2,
														}}
													/>
												</button>
											</div>
										</Col>
										<Col
											style={{ margin: "auto" }}
											onClick={() => removeProduct(product)}
											span={2}
										>
											<DeleteOutlined className="drawer-cart-shopicon" />
										</Col>
									</Row>
								);
							})}
							<Row justify="space-between">
								<Col>
									<b className="drawer-top">
										{Languages[language].totalitems}: {itemCount}
									</b>
								</Col>
								<Col onClick={() => clearCart()}>
									<b className="drawer-top-icon">{Languages[language].clear}</b>
								</Col>
							</Row>
						</>
					) : (
						<>
							<br />
							<center>
								<h3>
									<b>{Languages[language].empty}</b>
								</h3>
							</center>
							<br />
						</>
					)}
				</div>
			</Drawer>
		</>
	);
};

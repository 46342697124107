import React, { useState, useContext, useEffect } from "react";
import { AutoComplete, message } from "antd";
import axios from "axios";
import { Row, Col } from "antd";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import { ProfileContext } from "../contexts/LangContext";
import { Languages } from "../lang/translation";
import { useHistory } from "react-router-dom";
import { CartContext } from "../contexts/CartContext";
import { Link } from "react-router-dom";
import Placeholderimg from "../img/placeholder.png";
import Plus from "../img/icons/plus.svg";
import Minus from "../img/icons/minus.svg";
import ImageWebp from "./ImageWebp";
import "./style.css";
import { Server } from "../utils/server";
const { Option } = AutoComplete;
export const Search = (props) => {
  let history = useHistory();
  const goSearch = (props) => {
    if (search.length > 1) {
      history.push({
        pathname: "/search",
        search: `?query=${search}`,
      });
    }
  };

  const goEnter = (event) => {
    if (event.keyCode === 13) {
      goSearch();
    }
  };

  const {
    addProduct,
    cartItems,
    increase,
    decrease,
    removeProduct,
    // added by bezirgen
    cardChecked,
    //
  } = useContext(CartContext);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState([]);
  const [load, setLoad] = useState(false);
  const [result, setResult] = useState([]);

  const { language } = useContext(ProfileContext);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 2) {
        DataSearch();
      }
      // Send Axios request here
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const DataSearch = () => {
    setLoad(true);
    if (search.length > 0) {
      axios
        .get(Server() + "/api/search", {
          params: {
            query: search,
            limit: 25,
          },
        })
        .then((res) => {
          if (res.data !== 0) {
            setResult(res.data.rows);
            setCount(res.data.count);
          }
          setLoad(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const isInCart = (data) => {
    return !!cartItems.find((item) => item.p_id === data.p_id);
  };

  return (
    <>
      <div className="search-select">
        <AutoComplete
          onChange={(e) => setSearch(e)}
          className="h-search"
          placeholder={Languages[language].srch}
          dropdownMatchSelectWidth={false}
          onKeyDown={(e) => goEnter(e)}
          notFoundContent={count < 1 && search.length > 2 && "Not found"}
          dropdownClassName="search-dropdawn"
        >
          {typeof result != "undefined" ? (
            <>
              {result.map((product) => {
                return (
                  <Option
                    className="search-option"
                    key={product.p_id}
                    value={product.p_name_en}
                    disabled
                  >
                    <Row className="cart-items-search">
                      <Col>
                        <div className="search-product-status">
                          {Number(product.new) === 1 ? (
                            <div className="search-product-new">
                              {Languages[language].new}
                            </div>
                          ) : null}
                          {product.sale && product.sale === "1" ? (
                            <div className="search-product-a">
                              {Math.round(
                                100 -
                                (product.p_price_3 / product.p_price_1) * 100
                              )}
                              %
                            </div>
                          ) : null}
                        </div>

                        <ImageWebp
                          style={{ float: "left" }}
                          alt="product"
                          className="search-product-img"
                          srcWebp={Server() + "/img/products/" + product.p_img}
                          src={
                            product.p_img
                              ? Server() +
                              "/img/productsj/" +
                              product.p_img.substr(
                                0,
                                product.p_img.lastIndexOf(".")
                              ) +
                              ".jpg"
                              : Placeholderimg
                          }
                          onError={(e) => {
                            e.target.src = Placeholderimg;
                          }}
                        />
                      </Col>
                      <Col span={9}>
                        <div className="cart-bottom-search">
                          <Link
                            to={
                              "/product/" +
                              product.categoryKId +
                              "/" +
                              product.p_id
                            }
                          >
                            <p className="cart-name-search">
                              {language === "en" ? (
                                <p className="cart-name-search">
                                  {product.p_name_en}
                                </p>
                              ) : language === "ru" ? (
                                <p className="cart-name-search">
                                  {product.p_name_ru}
                                </p>
                              ) : language === "tm" ? (
                                <p className="cart-name-search">
                                  {product.p_name_tm}
                                </p>
                              ) : (
                                <p className="cart-name-search">
                                  {product.p_name_en}
                                </p>
                              )}
                            </p>
                          </Link>
                          {product.sale && product.sale === "1" ? (
                            <p className="cart-price-search">
                              {product.p_price_3 % 1 !== 0
                                ? Number(product.p_price_3).toFixed(2)
                                : Number(product.p_price_3)}{" "}
                              man
                            </p>
                          ) : (
                            <p className="cart-price-search">
                              {product.p_price_1 % 1 !== 0
                                ? Number(product.p_price_1).toFixed(2)
                                : product.p_price_1}{" "}
                              man
                            </p>
                          )}
                        </div>

                        {!isInCart(product) && (
                          <button
                            className="cart-btn-search"
                            onClick={() => {
                              addProduct(product);
                              cardChecked(false)
                              // if (checkMayIncrease(product, cartItems)) {
                              //   addProduct(product);
                              // } else {
                              //   message.error(Languages[language].encreaseError, 3)
                              // }
                            }}
                          >
                            <b className="cart-btn-search-text">
                              {Languages[language].addcart}
                            </b>
                          </button>
                        )}
                        {isInCart(product) && (
                          <div className="cart-count-search">
                            {cartItems.find(
                              (item) => item.p_id === product.p_id
                            ).quantity === 1 ? (
                              <button
                                className="minus-search"
                                onClick={() => {
                                  removeProduct(product);
                                }}
                              >
                                <span className="srch-minus">
                                  <img src={Minus} alt="del" />
                                </span>
                              </button>
                            ) : (
                              <button
                                className="minus-search"
                                onClick={() => {
                                  decrease(product);
                                }}
                              >
                                <span className="srch-minus">
                                  <img
                                    src={Minus}
                                    alt="-"
                                    style={{ height: 17, width: 17 }}
                                  />
                                </span>
                              </button>
                            )}

                            <p className="counter-search">
                              {
                                cartItems.find(
                                  (item) => item.p_id === product.p_id
                                ).quantity
                              }
                            </p>
                            <button
                              className="plus-search"
                              onClick={() => {
                                increase(product);
                                cardChecked(false)
                                // if (checkMayIncrease(product, cartItems)) {
                                //   increase(product);
                                // } else {
                                //   message.error(Languages[language].encreaseError, 3)
                                // }
                              }}
                            >
                              <span className="srch-plus">
                                <img
                                  style={{ height: 17, width: 17 }}
                                  src={Plus}
                                  alt="+"
                                />
                              </span>
                            </button>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Option>
                );
              })}
              {count > 0 ? (
                <Option className="search-option" disabled>
                  {count > 10 ? (
                    <b
                      onClick={() => goSearch()}
                      style={{
                        textAlign: "left",
                        cursor: "pointer",
                        position: "absolute",
                        zIndex: 1000,
                      }}
                    >
                      {Languages[language].seeall}
                    </b>
                  ) : null}
                  <b style={{ float: "right" }}>
                    {Languages[language].result}: {count}
                    {/* result: {typeof count !== "undefined" ? count : "0"} */}
                  </b>
                </Option>
              ) : null}
            </>
          ) : null}
        </AutoComplete>
        {load ? (
          <LoadingOutlined className="load-search" spin />
        ) : (
          <SearchOutlined className="search-btn" onClick={() => goSearch()} />
        )}
      </div>
    </>
  );
};

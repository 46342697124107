import React, { createContext, useState, useEffect } from "react";
import SecureLS from "secure-ls";

var ls = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: "-*follow-the-technology-profile*-",
});

try {
  ls.get("-secure-token-");
} catch (err) {
  console.log("err token");
  ls.removeAll();
}

export const ProfileContext = createContext();

const ProfileContextProvider = ({ children }) => {
  const [language, setLanguage] = useState("ru");
  const data = ls.get("-secure-token-")
    ? JSON.parse(ls.get("-secure-token-"))
    : [];
  const profile = {
    ...data,
    lang: language,
  };

  useEffect(() => {
    if (ls.get("-secure-token-")) {
      var data = JSON.parse(ls.get("-secure-token-"));
      if (data.lang === "tm" || data.lang === "en" || data.lang === "ru") {
        setLanguage(data.lang);
      }
    }
  }, []);

  useEffect(() => {
    ls.set("-secure-token-", JSON.stringify(profile));
  }, [language]);

  return (
    <ProfileContext.Provider value={{ language, setLanguage }}>
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileContextProvider;

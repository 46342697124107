import SecureLS from "secure-ls";
import { getProfile } from "../hooks/useProfile";

var ls = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: "-*follow-the-technology-profile*-",
});

try {
  ls.get("-secure-token-");
} catch (err) {
  console.log("err token");
  ls.removeAll();
}

export const logout = () => {
  ls.remove("-secure-token-");
};

export const isLogin = () => {
  if (ls.get("-secure-token-")) {
    var data = JSON.parse(ls.get("-secure-token-"));
    if (data.token) {
      return true;
    } else {
      ls.remove("-secure-token-");
    }
  }
  return false;
};

export const isLoginAdmin = () => {
  if (ls.get("-secure-token-")) {
    var data = JSON.parse(ls.get("-secure-token-"));
    if (data.manager && data.token) {
      return true;
    } else {
      ls.remove("-secure-token-");
    }
  } else {
    return false;
  }
};

import React, { createContext, useReducer } from "react";
import { FaveReducer } from "./faveReducer";
import SecureLS from "secure-ls";
var ls = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: "-*follow-the-technology-fave*-",
});
export const FaveContext = createContext();

try {
  ls.get("-secure-fave-");
} catch (err) {
  console.log("err");
  ls.removeAll();
}
const storage = ls.get("-secure-fave-")
  ? JSON.parse(ls.get("-secure-fave-"))
  : [];

const initialState = {
  faveItems: storage,
};

const FaveContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(FaveReducer, initialState);

  const addFave = (payload) => {
    dispatch({ type: "ADD_ITEM", payload });
  };

  const removeFave = (payload) => {
    dispatch({ type: "REMOVE_ITEM", payload });
  };

  const UpdateFave = (payload) => {
    dispatch({ type: "UPDATE", payload });
  };

  const clearFave = () => {
    dispatch({ type: "CLEAR" });
  };

  const contexts = {
    removeFave,
    addFave,
    clearFave,
    UpdateFave,
    ...state,
  };

  return (
    <FaveContext.Provider value={contexts}>{children}</FaveContext.Provider>
  );
};

export default FaveContextProvider;

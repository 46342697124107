import SecureLS from "secure-ls";

var ls = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: "-*follow-the-technology-profile*-",
});

export const logout = () => {
  ls.removeAll("-secure-token-");
  window.location.reload();
};

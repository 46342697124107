import React from "react";
import Routes from "./routes/routes";
import { QueryClient, QueryClientProvider } from "react-query";
import "./index.css";
import "./App.css";

import CartContextProvider from "./contexts/CartContext";
import FaveContextProvider from "./contexts/faveContext";
import ProfileContextProvider from "./contexts/LangContext";
import LangContextProvider from "./contexts/LangContext";



export default class Main extends React.Component {


  render() {
    const queryClient = new QueryClient();
    const spinner = document.getElementById("spinner");
    if (spinner && !spinner.hasAttribute("hidden")) {
      spinner.setAttribute("hidden", "true");
    }

    return (
      <QueryClientProvider client={queryClient}>
        <ProfileContextProvider>
          <CartContextProvider>
            <FaveContextProvider>
              <LangContextProvider>
                <Routes />
              </LangContextProvider>
            </FaveContextProvider>
          </CartContextProvider>
        </ProfileContextProvider>
      </QueryClientProvider>
    );
  }
}

import SecureLS from "secure-ls";

var ls = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: "-*follow-the-technology-profile*-",
});

try {
  ls.get("-secure-token-");
} catch (err) {
  console.log("err token");
  ls.removeAll();
}

export const LangChange = (lang) => {
  const profile = {
    ...JSON.parse(ls.get("-secure-token-")),
    lang: lang,
  };
  ls.set("-secure-token-", JSON.stringify(profile));
};

export const lang = () => {
  if (ls.get("-secure-token-")) {
    var data = JSON.parse(ls.get("-secure-token-"));
    if (data.lang === "tm" || data.lang === "en" || data.lang === "ru") {
      return data.lang;
    }
  }
  return "en";
};

import { useQuery } from "react-query";
import { axiosInstance } from "../utils/server";

const getCategories = async () => {
  const { data, error } = await axiosInstance.get("/api/category_list");
  return data;
};

const getSmartCategories = async () => {
  const { data, error } = await axiosInstance.get("/api/smart_list");

  return data;
};

const getShowCategories = async (id) => {
  const getid = id.queryKey[1];

  const { data, error } = await axiosInstance.get(
    "/api/category_show/" + getid
  );
  return data;
};

const getShowSubCategories = async (id) => {
  const getid = id.queryKey[1];
  const { data, error } = await axiosInstance.get("/api/category_sub/" + getid);
  return data;
};

const showSmartCategories = async (id) => {
  const getid = id.queryKey[1];

  const { data, error } = await axiosInstance.get("/api/smart_show/" + getid);
  console.log(data);
  return data;
};

export function useCategories() {
  return useQuery("categories", getCategories);
}

export function useSmartCategories() {
  return useQuery("smartCategories", getSmartCategories);
}

export function useShowCategories(id) {
  // console.log('Params1:' + id);
  return useQuery(["showCategories", id], getShowCategories);
}

export function useShowSubCategories(id) {
  // console.log('Params1:' + id);
  return useQuery(["showSubCategories", id], getShowSubCategories);
}

export function useShowSmartCategories(id) {
  // console.log('Params1:' + id);
  return useQuery(["showSmartCategories", id], showSmartCategories);
}

import SecureLS from "secure-ls";

var ls = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: "-*follow-the-technology-profile*-",
});

try {
  ls.get("-secure-token-");
} catch (err) {
  console.log("err profile");
  ls.removeAll();
}
export const token = () => {
  if (ls.get("-secure-token-")) {
    const data = ls.get("-secure-token-")
      ? JSON.parse(ls.get("-secure-token-"))
      : [];

    return data.token;
  }
};

import React, { useContext, useState } from "react";

import {
  HeartFilled,
  HeartOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import { Languages } from "../../lang/translation";
import { ProfileContext } from "../../contexts/LangContext";
import Placeholderimg from "../../img/placeholder.png";
import { CartContext } from "../../contexts/CartContext";
import { FaveContext } from "../../contexts/faveContext";
import { Server } from "../../utils/server";
import Shoppingcart from "../../img/icons/cartwhite.svg";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import ImageWebp from "../../components/ImageWebp";
import "./style.css";
import Plus from "../../img/icons/plus.svg";
import Minus from "../../img/icons/minus.svg";
import { message } from "antd";

export const Card = ({ row }) => {
  //   const [Item, setItem] = useState([]);

  const { addProduct, cartItems, increase, removeProduct, decrease } =
    useContext(CartContext);
  const { addFave, faveItems, removeFave } = useContext(FaveContext);
  const { language } = useContext(ProfileContext);
  let [load, setLoad] = useState(false);

  const isInCart = (data) => {
    return !!cartItems.find((item) => item.p_id === data.p_id);
  };

  return (
    <>
      <div className="product-status">
        {Number(row.new) === 1 ? (
          <div className="product-new">{Languages[language].new}</div>
        ) : null}
        {row.sale && row.sale === "1" ? (
          <div className="product-a">
            {/* {Math.round(
              ((row.p_price_1 * row.product_unit) /
                (row.p_price_3 * row.product_unit)) *
                100 -
                100
            )} */}
            {Math.round(
              100 -
              ((row.p_price_3 * row.product_unit) /
                (row.p_price_1 * row.product_unit)) *
              100
            )}
            %
          </div>
        ) : null}
      </div>

      <div className="p-card">
        <LazyLoad>
          {!load ? (
            <div>
              <div className="shimmerBG media"></div>
              <div className="p-32">
                <div className="shimmerBG title-line"></div>
                <div className="shimmerBG title-line end"></div>

                <div className="shimmerBG content-line m-t-24"></div>
                <div className="shimmerBG content-line"></div>

                <div className="shimmerBG content-line end"></div>
              </div>
            </div>
          ) : null}
          <div>
            <Link to={"/product/" + row.categoryKId + "/" + row.p_id}>
              <center>
                <ImageWebp
                  hidden={!load}
                  className="p-img"
                  srcWebp={Server() + "/img/products/" + row.p_img}
                  src={
                    row.p_img
                      ? Server() +
                      "/img/productsj/" +
                      row.p_img.substr(0, row.p_img.lastIndexOf(".")) +
                      ".jpg"
                      : Placeholderimg
                  }
                  onLoad={() => setLoad(true)}
                  onError={(e) => {
                    e.target.src = Placeholderimg;
                  }}
                />

                {/* <picture>
                  <source
                    srcset={
                      row.p_img
                        ? Server() + "/img/products/" + row.p_img
                        : Placeholderimg
                    }
                    type="image/webp"
                  />
                  <source
                    srcset={
                      row.p_img
                        ? Server() +
                          "/img/productsj/" +
                          row.p_img.substr(0, row.p_img.lastIndexOf(".")) +
                          ".jpg"
                        : Placeholderimg
                    }
                    type="image/jpeg"
                  />

                  <img
                    src={
                      row.p_img
                        ? Server() +
                          "/img/productsj/" +
                          row.p_img.substr(0, row.p_img.lastIndexOf(".")) +
                          ".jpg"
                        : Placeholderimg
                    }
                    hidden={!load}
                    alt="product"
                    className="p-img"
                    onLoad={() => setLoad(true)}
                    onError={(e) => {
                      e.target.src = Placeholderimg;
                    }}
                  />
                </picture> */}

                {/* <img
                  hidden={!load}
                  alt="product"
                  className="p-img"
                  src={
                    row.p_img === null || row.p_img === ""
                      ? Placeholderimg
                      : Server() + "/img/products/" + row.p_img ||
                        Server() +
                          "/img/productsj/" +
                          row.p_img.substr(0, row.p_img.lastIndexOf(".")) +
                          ".jpg"
                  }
                  onLoad={() => setLoad(true)}
                  onError={(e) => {
                    e.target.src =
                      Server() +
                        "/img/productsj/" +
                        row.p_img.substr(0, row.p_img.lastIndexOf(".")) +
                        ".jpg" || Placeholderimg;
                  }}
                /> */}
              </center>
            </Link>
            {load && (
              <div>
                <span className="p-saved">
                  {faveItems.find((item) => item.p_id === row.p_id) ? (
                    <HeartFilled
                      onClick={() => {
                        removeFave(row);
                      }}
                      className="p-saved-img-active"
                    />
                  ) : (
                    <HeartOutlined
                      onClick={() => {
                        addFave(row);
                      }}
                      className="p-saved-img"
                    />
                  )}
                </span>
                {!isInCart(row) && (
                  <>
                    <button
                      className="cart-btn"
                      onClick={() => {
                        addProduct(row);
                        // if (checkMayIncrease(row, cartItems)) {
                        //   addProduct(row);
                        // } else {
                        //   message.error(Languages[language].encreaseError, 3)
                        // }
                      }}
                    >
                      <b className="card-add-text">
                        {Languages[language].addcart}
                      </b>
                    </button>
                    <div className="card-shop-icon">
                      <img
                        src={Shoppingcart}
                        className="card-shopping-icon"
                        alt="add"
                      />
                    </div>
                    {/* <ShoppingOutlined  /> */}
                  </>
                )}
                {isInCart(row) && (
                  <div className="cart-count">
                    {cartItems.find((item) => item.p_id === row.p_id)
                      .quantity === 1 ? (
                      <button
                        className="minus"
                        onClick={() => {
                          removeProduct(row);
                        }}
                      >
                        <span className="icon-minus">
                          <img
                            src={Minus}
                            style={{ height: 17, width: 17 }}
                            alt="minus"
                          />
                        </span>
                      </button>
                    ) : (
                      <button
                        className="minus"
                        onClick={() => {
                          decrease(row);
                        }}
                      >
                        <span className="icon-minus">
                          <img
                            src={Minus}
                            alt="-"
                            style={{ height: 17, width: 17 }}
                          />
                        </span>
                      </button>
                    )}

                    <p className="counter">
                      {
                        cartItems.find((item) => item.p_id === row.p_id)
                          .quantity
                      }
                    </p>

                    <button
                      className="plus"
                      onClick={() => {
                        
                        increase(row);
                        // if (checkMayIncrease(row, cartItems)) {
                          // increase(row);
                        // } else {
                        //   message.error(Languages[language].encreaseError, 3)
                        // }
                      }}
                    >
                      <span className="icon-plus">
                        <img
                          src={Plus}
                          alt="+"
                          style={{ height: 17, width: 17 }}
                        />
                      </span>
                    </button>
                  </div>
                )}
                <div className="card-bottom">
                  {language === "en" ? (
                    <p className="p-name">{row.p_name_en}</p>
                  ) : language === "ru" ? (
                    <p className="p-name">{row.p_name_ru}</p>
                  ) : language === "tm" ? (
                    <p className="p-name">{row.p_name_tm}</p>
                  ) : (
                    <p className="p-name">{row.p_name_en}</p>
                  )}
                  {row.sale && row.sale === "1" ? (
                    <p className="price">
                      {Number(row.p_price_3 * row.product_unit) ===
                        row.p_price_3 * row.product_unit &&
                        (row.p_price_3 * row.product_unit) % 1 !== 0
                        ? Number(row.p_price_3 * row.product_unit).toFixed(2)
                        : row.p_price_3 * row.product_unit}{" "}
                      {Languages[language].man}
                    </p>
                  ) : (
                    <p className="price">
                      {Number(row.p_price_1 * row.product_unit) ===
                        row.p_price_1 * row.product_unit &&
                        (row.p_price_1 * row.product_unit) % 1 !== 0
                        ? Number(row.p_price_1 * row.product_unit).toFixed(2)
                        : row.p_price_1 * row.product_unit}{" "}
                      {Languages[language].man}
                    </p>
                  )}

                  {row.sale && row.sale === "1" ? (
                    <del style={{ color: "#FD0018", fontSize: 18 }}>
                      {Number(row.p_price_1 * row.product_unit) ===
                        row.p_price_1 * row.product_unit &&
                        (row.p_price_1 * row.product_unit) % 1 !== 0
                        ? Number(row.p_price_1 * row.product_unit).toFixed(2)
                        : row.p_price_1 * row.product_unit}{" "}
                      {Languages[language].man}
                    </del>
                  ) : row.premium && row.premium === "1" ? (
                    // <span className="premium">
                    //   {row.p_price_2} {Languages[language].withpremium}
                    // </span>
                    <div />
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </LazyLoad>
      </div>
    </>
  );
};

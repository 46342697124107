import SecureLS from "secure-ls";

var ls = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: "-*follow-the-technology-fave*-",
});
const Storage = async (Items) => {
  await ls.set("-secure-fave-", JSON.stringify(Items.length > 0 ? Items : []));
};

export const ItemController = (faveItems) => {
  Storage(faveItems);
  return "sucess";
};
export const FaveReducer = (state, action) => {
  switch (action.type) {
    case "ADD_ITEM":
      if (!state.faveItems.find((item) => item.p_id === action.payload.p_id)) {
        state.faveItems.push({
          ...action.payload,
        });
      }

      return {
        ...state,
        ...ItemController(state.faveItems),
        faveItems: [...state.faveItems],
      };
    case "REMOVE_ITEM":
      return {
        ...state,
        ...ItemController(
          state.faveItems.filter((item) => item.p_id !== action.payload.p_id)
        ),
        faveItems: [
          ...state.faveItems.filter(
            (item) => item.p_id !== action.payload.p_id
          ),
        ],
      };

    case "UPDATE":
      state.faveItems[
        state.faveItems.findIndex((item) => item.p_id === action.payload.p_id)
      ].p_price_1 = action.payload.p_price_1;

      return {
        ...state,
        ...ItemController(state.faveItems),
        faveItems: [...state.faveItems],
      };

    case "CLEAR":
      return {
        ...ItemController([]),
        faveItems: [],
      };
    default:
      return state;
  }
};

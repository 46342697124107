import { useQuery } from "react-query";
import { axiosInstance } from "../utils/server";

const getBanners = async () => {
  const { data, error } = await axiosInstance.get("/api/banner_list");

  return data;
};

export default function useBanners() {
  return useQuery("banners", getBanners);
}

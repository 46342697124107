import { useQuery } from "react-query";
import { axiosInstance } from "../utils/server";
import { token } from "../components/token";
import { logout } from "../components/logout";

export const getProfile = async () => {
  if (token()) {
    const { data, error } = await axiosInstance.get("/api/user_show/", {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token(),
      },
    });
    if (data.token === false) {
      logout()
    } else {
      return data;
    }
  }

  return [];
};

export default function useProfile() {
  return useQuery("profile", getProfile);
}

import React, { useContext } from "react";
import { List } from "antd";
import { BASE_URL, Server } from "../utils/server";
import { TagsOutlined, RightOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { useCategories } from "../hooks/useCategories";
import { Languages } from "../lang/translation";
import { ProfileContext } from "../contexts/LangContext";
import CatalogIcon from "../img/icons/catalog.svg";
import "./style.css";
const Sidebar = () => {
  const { language } = useContext(ProfileContext);
  const {
    isLoading: categories_load,
    error: categories_error,
    data: categories,
    refetch: categoriesFetch,
  } = useCategories();

  return (
    <div className="h-sidebar r-none">
      <List
        className="category-list"
        style={{ minHeight: 350, paddingBottom: 6 }}
      >
        <List.Item className="h-cat">
          <img
            src={CatalogIcon}
            alt="catalog icon"
            style={{ marginRight: 10, marginTop: -2 }}
          />
          {Languages[language].catalog}
        </List.Item>
        {!categories_load && !categories_error
          ? categories.map((row, count) => {
              return (
                <List.Item
                  key={count}
                  style={{ border: 0, margin: 0, padding: 0 }}
                >
                  <div className="sidebar-menu">
                    <div>
                      <NavLink
                        className="h-catlist"
                        activeClassName="activee"
                        to={
                          "/category/" +
                          row.k_name_en.split(" ").join("-").toLowerCase() +
                          "-" +
                          row.k_id
                        }
                      >
                        <img
                          style={{
                            height: 20,
                            width: 20,
                            marginRight: 6,
                            marginTop: -4,
                          }}
                          alt="Icon"
                          src={BASE_URL + "/img/categories/" + row.k_icon}
                        />
                        {language === "en"
                          ? row.k_name_en
                          : language === "ru"
                          ? row.k_name_ru
                          : language === "tm"
                          ? row.k_name_tm
                          : row.k_name_en}
                        {row.sub_categories.length > 0 ? (
                          <RightOutlined className="category-icon-right" />
                        ) : null}
                      </NavLink>
                    </div>
                    {row.sub_categories.length > 0 ? (
                      <>
                        <ul
                          key={count}
                          className="sub-cat-cont"
                          style={
                            Number(count) > 5 && row.sub_categories.length > 5
                              ? {
                                  position: "absolute",
                                  top:
                                    -row.sub_categories.length * 20.8 + count,
                                  zIndex: 1400,
                                }
                              : null
                          }
                        >
                          {row.sub_categories.map((r, index) => {
                            return (
                              <div key={index}>
                                <NavLink
                                  activeClassName="subcat-active"
                                  className="subcat"
                                  to={
                                    "/category/" +
                                    row.k_name_en
                                      .split(" ")
                                      .join("-")
                                      .toLowerCase() +
                                    "-" +
                                    row.k_id +
                                    "/" +
                                    r.sc_name_en
                                      .split(" ")
                                      .join("-")
                                      .toLowerCase() +
                                    "-" +
                                    r.sc_id
                                  }
                                >
                                  <li>
                                    {language === "en"
                                      ? r.sc_name_en
                                      : language === "ru"
                                      ? r.sc_name_ru
                                      : language === "tm"
                                      ? r.sc_name_tm
                                      : r.sc_name_en}
                                  </li>
                                </NavLink>
                              </div>
                            );
                          })}
                        </ul>
                      </>
                    ) : null}
                  </div>
                </List.Item>
              );
            })
          : null}
      </List>

      <List>
        <List.Item className="brands-list">
          <NavLink
            to="/allbrands"
            style={{ borderLeft: 0, paddingLeft: 8 }}
            activeClassName="activee"
            className="h-catlist brands-list-item"
          >
            <TagsOutlined style={{ fontSize: 22, marginRight: 2 }} />
            <span className="brands-text">{Languages[language].brands}</span>
          </NavLink>
        </List.Item>
      </List>
    </div>
  );
};

export default Sidebar;

import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import { useFormik } from "formik";
import style from "./classes.module.css";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  LoadingOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { axiosInstance } from "../../utils/server";
import { message, Select } from "antd";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

export const getAddresses = async () => {
  const res = await axiosInstance.get(`/api/all/regions`);
  return res.data.data;
};

export const Registration = ({
  Languages,
  language,
  noaccount,
  load,
  setlogin,
  Setvisible,
  setload,
  setNoaccount,
  ls,
}) => {
  const [submited, setSubmited] = useState(false);

  const {
    data: addresses,
    isError: addressesError,
    isLoading: addressesLoading,
  } = useQuery("regions", getAddresses);

  let history = useHistory();

  const formRegistration = useFormik({
    initialValues: {
      u_phone_1: "",
      u_password: "",
      confirmPassword: "",
      u_name: "",
      u_address_1: "",
      regions: "",
    },
    validationSchema: Yup.object({
      u_phone_1: Yup.string().test(
        "len",
        (val) => Number(val) >= 1000000 && Number(val) <= 5999999
      ),
      u_password: Yup.string().required().min(4),
      confirmPassword: Yup.string()
        .oneOf(
          [Yup.ref("u_password"), null],
          Languages[language].passwordconfirm
        )
        .required(),
      u_name: Yup.string().required().min(3),
      u_address_1: Yup.string().required().min(3),
      regions: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      try {
        setload(true);

        const formData = new FormData();

        formData.append("u_phone_1", values.u_phone_1);
        formData.append("u_password", values.u_password);
        formData.append("confirmPassword", values.confirmPassword);
        formData.append("u_name", values.u_name);
        formData.append(
          "u_address_1",
          values.u_address_1 + "," + values.regions
        );
        const response = await axiosInstance.post(`/api/set_user`, formData);
        console.log(response);
        if (response.data.message === "Suссessfully") {
          message.success(Languages[language].registrationSuccess);
          ls.set(
            "-secure-token-",
            JSON.stringify({
              token: response.data.token,
              lang: response.data.user_lang,
              login: true,
            })
          );
          Setvisible(false);
          history.push("/profile");
        }

        setload(false);
      } catch (err) {
        console.error(err);
        setload(false);
        if (err.response.status === 401) {
          message.warn(Languages[language].registered);
        } else if (err.response.status === 403) {
          message.warn(Languages[language].bann);
        } else {
          message.error(Languages[language].somethingWentWrong);
        }
      }
    },
  });

  return (
    <>
      {/* Registration */}
      <form onSubmit={formRegistration.handleSubmit}>
        <div className="login-title">{Languages[language].welcome}</div>
        <div className="login-close" onClick={Setvisible}>
          <PlusOutlined className="login-close-icon" />
        </div>
        <p className="login-name">{Languages[language].signup}</p>

        {/* Phone number field */}
        <p className="login-name">
          {noaccount
            ? Languages[language].noaccount
            : Languages[language].signuptext}
        </p>
        <div
          className="login-input"
          style={{
            height: "55px",
            paddingRight: "10px",
            // borderColor: formRegistration.touched.u_phone_1 && formRegistration.errors.u_phone_1 ? "red" : null
          }}
        >
          <div className="belgi-name">{Languages[language].phonenumber}</div>
          <b className="belgi">+993 6</b>
          <input
            type="number"
            className="login-input-text-number"
            {...formRegistration.getFieldProps("u_phone_1")}
            style={{
              border: 1,
              width: "75%",
              fontSize: 18,
              color: "#000",
              fontWeight: "bold",
            }}
          />
          {formRegistration.touched.u_phone_1 &&
          formRegistration.errors.u_phone_1 &&
          submited ? (
            <CloseCircleTwoTone
              twoToneColor="#F00A0A"
              style={{
                fontSize: "16px",
                position: "absolute",
                right: "35px",
              }}
            />
          ) : submited ? (
            <CheckCircleTwoTone
              twoToneColor="#FF7700"
              style={{
                fontSize: "16px",
                position: "absolute",
                right: "35px",
              }}
            />
          ) : null}
        </div>

        {/* Password field */}

        <p
          className="login-name"
          style={{ marginBottom: "5px", marginTop: "15px" }}
        >
          {Languages[language].passwordtext}
        </p>
        <div
          className="login-input"
          style={{
            height: "55px",
            paddingRight: "30px",
            // borderColor: formRegistration.touched.u_password && formRegistration.errors.u_password ? "red" : null
          }}
        >
          {Languages[language].pass}

          <input
            type="password"
            style={{ outline: "none", border: 0 }}
            className="login-input-text-pass"
            {...formRegistration.getFieldProps("u_password")}
          />

          {formRegistration.touched.u_password &&
          formRegistration.errors.u_password &&
          submited ? (
            <CloseCircleTwoTone
              twoToneColor="#F00A0A"
              style={{
                fontSize: "16px",
                position: "absolute",
                right: "35px",
              }}
            />
          ) : submited ? (
            <CheckCircleTwoTone
              twoToneColor="#FF7700"
              style={{
                fontSize: "16px",
                position: "absolute",
                right: "35px",
              }}
            />
          ) : null}
        </div>

        {/* Confirm password field */}
        <p
          className="login-name"
          style={{ marginBottom: "5px", marginTop: "15px" }}
        >
          {Languages[language].passwordconfirm}
        </p>
        <div
          className="login-input"
          style={{
            height: "55px",
            paddingRight: "30px",
            // borderColor: formRegistration.touched.confirmPassword && formRegistration.errors.confirmPassword ? "red" : null,
            display: "flex",
            alignContent: "center",
          }}
        >
          <input
            className={style.Input}
            type="password"
            {...formRegistration.getFieldProps("confirmPassword")}
            style={{
              border: 1,
              fontSize: 18,
              width: "100%",
              color: "#000",
              fontWeight: "bold",
            }}
          />

          {formRegistration.touched.confirmPassword &&
          formRegistration.errors.confirmPassword &&
          submited ? (
            <CloseCircleTwoTone
              twoToneColor="#F00A0A"
              style={{
                fontSize: "16px",
                position: "absolute",
                right: "35px",
                paddingTop: "15px",
              }}
            />
          ) : submited ? (
            <CheckCircleTwoTone
              twoToneColor="#FF7700"
              style={{
                fontSize: "16px",
                position: "absolute",
                right: "35px",
                paddingTop: "15px",
              }}
            />
          ) : null}
        </div>

        {/* User name field */}
        <p
          className="login-name"
          style={{ marginBottom: "5px", marginTop: "15px" }}
        >
          {Languages[language].nameless}
        </p>
        <div
          className="login-input"
          style={{
            height: "55px",
            paddingRight: "30px",
            // borderColor: formRegistration.touched.u_name && formRegistration.errors.u_name ? "red" : null,
            display: "flex",
            alignContent: "center",
          }}
        >
          <input
            className={style.Input}
            type="text"
            {...formRegistration.getFieldProps("u_name")}
            style={{
              border: 1,
              fontSize: 18,
              width: "100%",
              color: "#000",
              fontWeight: "bold",
            }}
          />
          {formRegistration.touched.u_name &&
          formRegistration.errors.u_name &&
          submited ? (
            <CloseCircleTwoTone
              twoToneColor="#F00A0A"
              style={{
                fontSize: "16px",
                position: "absolute",
                right: "35px",
                paddingTop: "15px",
              }}
            />
          ) : submited ? (
            <CheckCircleTwoTone
              twoToneColor="#FF7700"
              style={{
                fontSize: "16px",
                position: "absolute",
                right: "35px",
                paddingTop: "15px",
              }}
            />
          ) : null}
        </div>

        {/* Address selection */}

        <p
          className="login-name"
          style={{ marginBottom: "5px", marginTop: "15px" }}
        >
          {Languages[language].addaddress}
        </p>
        <Select
          showSearch
          suffixIcon={
            formRegistration.touched.regions &&
            formRegistration.errors.regions &&
            submited ? (
              <CloseCircleTwoTone
                twoToneColor="#F00A0A"
                style={{
                  fontSize: "16px",
                  position: "relative",
                  right: "4px",
                }}
              />
            ) : submited ? (
              <CheckCircleTwoTone
                twoToneColor="#FF7700"
                style={{
                  fontSize: "16px",
                  position: "relative",
                  right: "4px",
                }}
              />
            ) : (
              <DownOutlined />
            )
          }
          size="large"
          style={{
            width: "100%",
            borderRadius: "8px",
            color: "black",
            fontWeight: "bold",
            fontSize: "16px",
          }}
          placeholder={Languages[language].address + "..."}
          optionFilterProp="children"
          onChange={(value) => formRegistration.setFieldValue("regions", value)}
        >
          {addressesLoading ? (
            <Select.Option>
              <LoadingOutlined />
            </Select.Option>
          ) : (
            addresses.map((address) => {
              return (
                <Select.Option key={address.id} value={address.regions}>
                  {address.regions}
                </Select.Option>
              );
            })
          )}
        </Select>

        {/* Address field */}
        <p
          className="login-name"
          style={{ marginBottom: "5px", marginTop: "15px" }}
        >
          {Languages[language].addAddressInRegistrationMoreDetails}
        </p>
        <div
          className="login-input"
          style={{
            padding: "0",
            height: "80px",
            // borderColor: formRegistration.touched.u_address_1 && formRegistration.errors.u_address_1 ? "red" : null,
            display: "flex",
            alignContent: "center",
            color: "black",
          }}
        >
          <textarea
            rows="6"
            maxLength="500"
            {...formRegistration.getFieldProps("u_address_1")}
            className={style.TextArea}
            placeholder={Languages[language].address + "..."}
          />
          {formRegistration.touched.u_address_1 &&
          formRegistration.errors.u_address_1 &&
          submited ? (
            <CloseCircleTwoTone
              twoToneColor="#F00A0A"
              style={{
                fontSize: "16px",
                position: "absolute",
                right: "35px",
                paddingTop: "15px",
              }}
            />
          ) : submited ? (
            <CheckCircleTwoTone
              twoToneColor="#FF7700"
              style={{
                fontSize: "16px",
                position: "absolute",
                right: "35px",
                paddingTop: "15px",
              }}
            />
          ) : null}
        </div>

        <input
          style={{ marginTop: "20px" }}
          className={`${style.cOrderbutton} ${style.red_color}`}
          type="submit"
          onClick={() => {
            setSubmited(true);
          }}
          value={load ? Languages[language].wait : Languages[language].sendcode}
          disabled={load}
        />
        <div onClick={() => setlogin("signin")} className="login-footer">
          {/* {Languages[language].member}&nbsp;&nbsp;&nbsp; */}
          {/* <b style={{ cursor: "pointer", userSelect: "none" }}>
                                    {Languages[language].signin}
                                </b> */}
          <p className="login-name" style={{ marginTop: "15px" }}>
            {Languages[language].member}
          </p>
          <input
            style={{ marginTop: "5px" }}
            className={`${style.cOrderbutton} ${style.green_color}`}
            type="submit"
            value={load ? Languages[language].wait : Languages[language].signin}
            disabled={load}
          />
        </div>
      </form>
    </>
  );
};

import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import clasess from "./classes.module.css";
import { PlusOutlined } from "@ant-design/icons";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { axiosInstance } from "../../utils/server";
import { message } from "antd";

// added by Bezirgen
import { useHistory } from "react-router-dom";

export default function LoginUser({
  Languages,
  language,
  ls,
  Setvisible,
  load,
  setload,
  setlogin,
  setNoaccount,
}) {
  const [submited, setSubmited] = useState(false);

  let history = useHistory();

  const formLogin = useFormik({
    initialValues: {
      tel: "",
      parol: "",
    },
    validationSchema: Yup.object({
      tel: Yup.string().test("len", (val) => val?.length === 7),
      parol: Yup.string().required().min(4),
    }),
    onSubmit: async (values) => {
      try {
        setload(true);
        const response = await axiosInstance.post(`/api/signin`, values);
        console.log(response);
        if (response.data.login) {
          message.success(Languages[language].login);
          ls.set("-secure-token-", JSON.stringify(response.data));
          Setvisible(false);
          history.push("/profile");
        } else if (response.data.bann) {
          message.warn(Languages[language].bann);
        } else {
          message.warn(Languages[language].errpassorphone);
        }
        setNoaccount(false);
        setload(false);
      } catch (err) {
        console.error(err);
      }
    },
  });

  return (
    <form onSubmit={formLogin.handleSubmit}>
      <div className="login-title">{Languages[language].welcome}</div>

      <p className="login-name">{Languages[language].signin}</p>
      <div className="login-close" onClick={Setvisible}>
        <PlusOutlined className="login-close-icon" />
      </div>
      {/* <p className="login-name">{Languages[language].signin}</p> */}

      <p className="login-name">{Languages[language].signintext}</p>
      <div
        className="login-input"
        style={{
          paddingRight: "10px",
          // borderColor: formLogin.touched.tel && formLogin.errors.tel ? "red" : null
        }}
      >
        <div className="belgi-name">{Languages[language].phonenumber}</div>
        <b className="belgi">+993 6</b>
        <input
          autoFocus
          type="number"
          className="login-input-text-number"
          {...formLogin.getFieldProps("tel")}
          style={{
            border: 1,
            width: "75%",
            fontSize: 18,
            color: "#000",
            fontWeight: "bold",
          }}
        />
        {formLogin.touched.tel && formLogin.errors.tel && submited ? (
          <CloseCircleTwoTone
            twoToneColor="#F00A0A"
            style={{
              fontSize: "16px",
              position: "absolute",
              right: "35px",
            }}
          />
        ) : submited ? (
          <CheckCircleTwoTone
            twoToneColor="#FF7700"
            style={{
              fontSize: "16px",
              position: "absolute",
              right: "35px",
            }}
          />
        ) : null}
      </div>

      {/* password field */}
      <p
        className="login-name"
        style={{ marginBottom: "5px", marginTop: "15px" }}
      >
        {Languages[language].passwordtext}
      </p>
      <div
        className="login-input"
        style={{
          paddingRight: "30px",
          // borderColor: formLogin.touched.parol && formLogin.errors.parol ? "red" : null
        }}
      >
        {Languages[language].pass}

        <input
          type="password"
          style={{ outline: "none", border: 0 }}
          className="login-input-text-pass"
          {...formLogin.getFieldProps("parol")}
        />
        {formLogin.touched.parol && formLogin.errors.parol && submited ? (
          <CloseCircleTwoTone
            twoToneColor="#F00A0A"
            style={{
              fontSize: "16px",
              position: "absolute",
              right: "35px",
            }}
          />
        ) : submited ? (
          <CheckCircleTwoTone
            twoToneColor="#FF7700"
            style={{
              fontSize: "16px",
              position: "absolute",
              right: "35px",
            }}
          />
        ) : null}
      </div>
      {/* Reset Button */}
      {/* <b
                onClick={() => {
                    setlogin("reset");
                }}
                className="reset-pass"
            >
                {Languages[language].resetpass} ?
            </b> */}

      {/* Login Button */}
      <input
        style={{ marginTop: "15px" }}
        className={`${clasess.cOrderbutton} ${clasess.green_color}`}
        type="submit"
        onClick={() => {
          setSubmited(true);
        }}
        value={load ? Languages[language].wait : Languages[language].signin}
        disabled={load}
      />

      <p className="login-name" style={{ marginTop: "25px" }}>
        {Languages[language].notRegisteredYet}
      </p>
      <button
        style={{ marginTop: "5px", marginBottom: "20px", textAlign: "center" }}
        className={`${clasess.cOrderbutton} ${clasess.red_color}`}
        onClick={() => {
          setlogin("signup");
        }}
        disabled={load}
      >
        {load ? Languages[language].wait : Languages[language].sendcode}
      </button>
    </form>
  );
}

import React, { Component, useContext } from "react";
import { Header } from "../../components/header";
import { Link, useHistory } from "react-router-dom";
import img from "../../img/404.svg";
import { Button } from "antd";
import "antd/dist/antd.css";
import { ProfileContext } from "../../contexts/LangContext";
import { Languages } from "../../lang/translation";
import { lang } from "../../lang/lang";
const NotFound = () => {
  const { language } = useContext(ProfileContext);
  let history = useHistory();
  return (
    <>
      <Header />
      <div
        style={{
          height: "100vh",
          width: "100%",
          backgroundColor: "#f2f5f7",
        }}
      >
        <div
          style={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            top: "20%",
          }}
        >
          <h1
            style={{
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: 48,
              textAlign: "center",
            }}
          >
            {Languages[language].notfound}
          </h1>
          <img
            src={img}
            style={{
              width: "326px",
              height: "130px",
            }}
            alt="404"
          />
          <h1
            style={{
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: 18,
              textAlign: "center",
              position: "relative",
              top: 10,
            }}
          >
            {Languages[language].notfoundtxt}
          </h1>

          <button
            style={{
              width: 190,
              height: 34,
              background: "#FF7700",
              color: "#fff",
              borderRadius: 4,
              outline: "none",
              border: 0,
              cursor: "pointer",
              position: "relative",
              top: 20,
              fontWeight: "bold",
            }}
            onClick={() => history.goBack()}
          >
            {Languages[language].back}
          </button>
        </div>
      </div>
    </>
  );
};

export default NotFound;

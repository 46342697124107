import React, { useEffect } from "react";
import logo_ from "../img/logo-gyrat-2.png";
import NProgress from "nprogress";

import "./load.css";
export const Loading = () => {
  useEffect(() => {
    NProgress.start();
    NProgress.configure({ showSpinner: false });
    NProgress.set(0.6);
    return () => {
      NProgress.done();
    };
  }, []);

  return <img src={logo_} alt="logo" className="main-loading" />;
};

import axios from "axios";
// const BASE_URL = "http://localhost:4000";
// const BASE_URL = "https://api.gyratcomputer.com";
// const BASE_URL = "https://server.gyratcomputer.com";
// const BASE_URL = "https://testserver.gyratcomputer.com";
// const BASE_URL = "http://192.168.11.141:4000";
// const BASE_URL = "http://45.147.178.31:4000";

// current url
const BASE_URL = "https://api.gyratcomputer.com";
// const BASE_URL = "http://192.168.102.94:4000";

export const Server = () => {
  return BASE_URL;
};

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  // headers: {
  //   verify: "internet_dukan_100haryt",
  // },
});

export { BASE_URL, axiosInstance };

import { lazy } from "react";
export { default as Home } from "./home/home";
export { default as NotFound } from "./404/404";
// export { default as Cart } from "./cart/cart";
// export { default as Profile } from "./profile/profile";
// export { default as _History } from "./history/orderhistory";
// export { default as Saved } from "./saved/saved";
// export { default as Category } from "./categories/categories";
// export { default as Search } from "./search/search";
// export { default as Subcategory } from "./categories/subcategory";
// export { default as Smartcategory } from "./categories/smart";
// export { default as AllBrands } from "./Brands/brands";
// export { default as BrandProducts } from "./Brands/BrandProducts";

export const AllBrands = lazy(() => import("./Brands/brands"));
export const Smartcategory = lazy(() => import("./categories/smart"));
export const Subcategory = lazy(() => import("./categories/subcategory"));
export const BrandProducts = lazy(() => import("./Brands/BrandProducts"));
export const Saved = lazy(() => import("./saved/saved"));
export const _History = lazy(() => import("./history/orderhistory"));
export const Profile = lazy(() => import("./profile/profile"));
export const Cart = lazy(() => import("./cart/cart"));
export const Search = lazy(() => import("./search/search"));
export const Category = lazy(() => import("./categories/categories"));
export const NewProducts = lazy(() => import("./new/new"));
export const ProductPage = lazy(() => import("./productPage/productPage"));
export const Payment = lazy(() => import("./FooterPages/payment"));
export const Ordering = lazy(() => import("./FooterPages/ordering"));
export const Faq = lazy(() => import("./FooterPages/faq"));
export const Partnership = lazy(() => import("./FooterPages/partnership"));
export const AboutUs = lazy(() => import("./FooterPages/aboutus"));
export const Contact = lazy(() => import("./FooterPages/contact"));
export const Sale = lazy(() => import("./sale/sale"));
export const Terms = lazy(() => import("./FooterPages/terms"));
export const Privacy = lazy(() => import("./FooterPages/privacy"));

import React, { useState, useContext, useEffect } from "react";
import { Badge, Dropdown, Menu, BackTop } from "antd";
import {
  ClockCircleOutlined,
  UserOutlined,
  HeartOutlined,
  GlobalOutlined,
  CustomerServiceOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";
import { Link, NavLink, useLocation } from "react-router-dom";
import { isAndroid, isIOS, isMobileOnly } from "react-device-detect";
import { Languages } from "../lang/translation";
import { CartContext } from "../contexts/CartContext";
import { ProfileContext } from "../contexts/LangContext";
import { Search } from "./search";
import { SignUp } from "../pages/login/auth";
import Shoppingcart from "../img/icons/cart.svg";
import logo_ from "../img/gyrat-logo.png";
import logo from "../img/logo-gyrat-2.png";
import close from "../img/icons/close_w.svg";
import { CartDrawer } from "../pages/cart/drawer";
import { isLogin } from "../utils";
import "./style.css";
import "../responsive.css";
import { useSmartCategories } from "../hooks/useCategories";
export const Header = () => {
  const location = useLocation();
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  function onClose() {
    setVisible(false);
  }
  const { language, setLanguage } = useContext(ProfileContext);
  const { total, itemCount } = useContext(CartContext);
  const [showResults, setShowResults] = useState(false);
  const [show, setShow] = useState(true);
  const Login = () => setShowResults(true);

  function PopCancel() {
    setShowResults(false);
  }

  const {
    isLoading: smart_load,
    error: smart_error,
    data: smart,
    refetch: smartFetch,
  } = useSmartCategories();

  const menu = (
    <Menu style={{ borderRadius: 8, padding: 0 }}>
      <Menu.Item
        style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
        className={language === "tm" ? "home-lang-active" : "home-lang"}
        onClick={() => setLanguage("tm")}
        key="1"
      >
        Turkmen
      </Menu.Item>
      <Menu.Item
        className={language === "en" ? "home-lang-active" : "home-lang"}
        onClick={() => setLanguage("en")}
        key="2"
      >
        English
      </Menu.Item>
      <Menu.Item
        style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
        className={language === "ru" ? "home-lang-active" : "home-lang"}
        onClick={() => setLanguage("ru")}
        key="3"
      >
        Russian
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={isMobileOnly && show ? { height: 50 } : null}>
      <div
        className="header-fixed"
        // style={visible ? { position: "absolute" } : null}
        style={visible || showResults ? { position: "fixed", right: 5 } : null}
      >
        {showResults ? (
          <SignUp visible={showResults} Setvisible={PopCancel} />
        ) : null}
        <CartDrawer
          visible={visible}
          onClose={onClose}
          Login={showResults}
          setLogin={setShowResults}
        />

        {isMobileOnly && show ? (
          <div
            style={{
              width: "100%",
              height: 50,

              backgroundColor: "#5D2F86",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img
                onClick={() => setShow(false)}
                src={close}
                style={{ marginLeft: "8%", height: 40, width: 40 }}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "4%",
                }}
              >
                <div
                  style={{
                    lineHeight: 1.2,
                    fontFamily: "Nunito",
                    fontWeight: "bold",
                    color: "#fff",
                  }}
                >
                  gyratcomputer.com
                </div>
                <div
                  style={{
                    lineHeight: 1.2,
                    fontFamily: "Nunito",
                    color: "#fff",
                    fontSize: 12,
                  }}
                >
                  {Languages[language].download}
                </div>
              </div>
            </div>
            {isAndroid ? (
              <a
                style={{ marginRight: "3%" }}
                href="intent:#Intent;scheme=http;package=com.gyratcomputer;S.content=WebContent;end"
              >
                <div
                  style={{
                    backgroundColor: "#5BB354",
                    padding: 5,
                    textAlign: "center",
                    paddingLeft: 8,
                    paddingRight: 8,
                    color: "#fff",
                    fontFamily: "Nunito",
                    borderRadius: 8,
                    fontSize: 16,
                  }}
                >
                  {Languages[language].yukle}
                </div>
              </a>
            ) : isIOS ? (
              <a
                style={{ marginRight: "3%" }}
                href="https://apps.apple.com/us/app/gyratcomputer/id1563218520"
                target="_blank"
              >
                <div
                  style={{
                    backgroundColor: "#5BB354",
                    padding: 5,
                    textAlign: "center",
                    paddingLeft: 8,
                    paddingRight: 8,
                    color: "#fff",
                    fontFamily: "Nunito",
                    borderRadius: 8,
                    fontSize: 16,
                  }}
                >
                  {Languages[language].yukle}
                </div>
              </a>
            ) : null}
          </div>
        ) : null}

        <div className="top-header">
          <div className="h-container menu">
            <div className="menu-items">
              <Link to="/">
                <img
                  src={logo_}
                  width={175}
                  // height={36}
                  alt="logo"
                  className="r-none"
                />
                <img src={logo} alt="logo" className="r-show logo-small" />
                <br />
                <span className="logo-bottom r-none">Follow the technolgy</span>
              </Link>
            </div>
            <div className="r-search menu-items">
              <Search />
            </div>
            <div className="r-none menu-items-time for-none">
              <ClockCircleOutlined className="menu-icons " />
              <b className="menu-text ">09:00-18:00</b>
            </div>
            <span className="h-line r-none  for-none" />
            <div className="r-none menu-items-lang">
              <Dropdown overlay={menu} overlayStyle={{ position: "fixed" }}>
                <div style={{ cursor: "pointer" }}>
                  <GlobalOutlined className="menu-icons" />
                  <b
                    className="menu-text"
                    style={{ textTransform: "uppercase" }}
                  >
                    {language === "tm" ? "TK" : language}
                  </b>
                </div>
              </Dropdown>
            </div>
            <span className="h-line r-none  " />
            <div className="r-none menu-items-contact">
              <Link to="/contactus">
                <CustomerServiceOutlined className="menu-icons" />
                <b className="menu-text menu-text-none ">
                  {Languages[language].contact}
                </b>
              </Link>
            </div>
            <span className="h-line r-none " />
            <div className="r-none menu-items-saved">
              {isLogin() ? (
                <Link to="/saved">
                  <HeartOutlined className="menu-icons" />
                  <b className="menu-text menu-text-none">
                    {Languages[language].fave}
                  </b>
                </Link>
              ) : (
                <Link to="#" onClick={Login}>
                  <HeartOutlined className="menu-icons" />
                  <b className="menu-text menu-text-none">
                    {Languages[language].fave}
                  </b>
                </Link>
              )}
            </div>
            <span className="h-line r-none" />
            <div className="r-none menu-items-profile">
              {isLogin() ? (
                <Link to="/profile">
                  <UserOutlined className="menu-icons" />
                  <b className="menu-text menu-text-none">
                    {Languages[language].profile}
                  </b>
                </Link>
              ) : (
                <>
                  <Link to="#" onClick={Login}>
                    <UserOutlined className="menu-icons" />
                    <b className="menu-text menu-text-none">
                      {Languages[language].profile}
                    </b>
                  </Link>
                </>
              )}
            </div>
            {/* Mobil >>>>> */}
            <div className="menu-items lang-mob">
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                placement="bottomCenter"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    userSelect: "none",
                  }}
                >
                  <GlobalOutlined className="menu-icons" />
                  <b
                    style={{
                      textTransform: "uppercase",
                      fontSize: 14,
                      position: "relative",
                      top: 8,
                      left: 2,
                      userSelect: "none",
                    }}
                  >
                    {language}
                  </b>
                </div>
              </Dropdown>
            </div>
            {/* Mobil <<<<<<<<<<< */}
            <div className="cart-width  r-none">
              <div
                className="r-sebet-div header-manat  menu-items-sebet r-none"
                onClick={
                  location.pathname !== "/cart"
                    ? showDrawer
                    : () => console.log("this is cart")
                }
              >
                <Badge
                  count={
                    itemCount > 0 ? (
                      <div style={{ color: "#fff" }} className="cart-badge">
                        {itemCount}
                      </div>
                    ) : null
                  }
                  overflowCount={99}
                  offset={[1, 1]}
                >
                  <img
                    src={Shoppingcart}
                    style={{
                      position: "relative",
                      top: -2,
                      left: -2,
                      width: 24,
                    }}
                    alt="cart"
                    className="r-sebet"
                  />
                </Badge>
                <b
                  className="r-none "
                  style={{
                    color: "#000",
                    marginLeft: 10,
                  }}
                >
                  {total % 1 !== 0 ? Number(total).toFixed(2) : total}
                  {" manat"}
                </b>
              </div>
            </div>
          </div>
        </div>
        <div className="h-container sm-cont">
          <div className="smart-categories r-none">
            {!smart_load && !smart_error
              ? smart.map((row, index) => (
                  <NavLink
                    key={index}
                    activeClassName={
                      row.new ? "tag-active-badge" : "tag-active"
                    }
                    className={row.new ? "tag-style-badge" : "tag-style"}
                    to={
                      "/smart/" +
                      row.sm_name_en.split(" ").join("-").toLowerCase() +
                      "-" +
                      row.sm_id
                    }
                  >
                    <Badge
                      dot={false}
                      count={
                        row.new ? (
                          <div style={{ color: "#fff" }} className="new-badge">
                            new
                          </div>
                        ) : null
                      }
                      offset={[16, 0]}
                    >
                      <div className="text-smart">
                        {language === "en"
                          ? row.sm_name_en
                          : language === "ru"
                          ? row.sm_name_ru
                          : language === "tm"
                          ? row.sm_name_tm
                          : row.sm_name_en}
                      </div>
                    </Badge>
                  </NavLink>
                ))
              : null}
          </div>
        </div>
        <BackTop>
          <div
            style={{
              backgroundColor: "#FF7700",

              height: 35,
              width: 35,
              borderRadius: "50%",
              textAlign: "center",
            }}
          >
            <DoubleRightOutlined
              style={{
                color: "#fff",
                alignItems: "center",
                position: "relative",
                top: 8,
                fontSize: 17,
              }}
              rotate={-90}
            />
          </div>
        </BackTop>
      </div>
    </div>
  );
};

export default Header;

import React, { lazy, Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
// HashRouter as BrowserRouter
import SignIn from "../components/SignIn";

import history from "./history";

import test from "../pages/test";
import ScrollIntoView from "./ScrollIntoView";
import { Loading } from "../components/loader";
import Appdownload from "../pages/DownloadApp";
import {
  Home,
  NotFound,
  Cart,
  Profile,
  _History,
  Saved,
  Category,
  Search,
  Subcategory,
  Terms,
  Smartcategory,
  AllBrands,
  BrandProducts,
  NewProducts,
  ProductPage,
  Payment,
  Ordering,
  Faq,
  Partnership,
  AboutUs,
  Sale,
  Privacy,
  Contact,
} from "../pages";
import Snowfall from "react-snowfall";

import img1 from "../img/snowflakes/1.webp";
import img2 from "../img/snowflakes/2.webp";
import img3 from "../img/snowflakes/3.webp";
import img4 from "../img/snowflakes/4.webp";
import img5 from "../img/snowflakes/5.webp";
import img6 from "../img/snowflakes/6.webp";

const snowflake1 = document.createElement("img");
const snowflake2 = document.createElement("img");
const snowflake3 = document.createElement("img");
const snowflake4 = document.createElement("img");
const snowflake5 = document.createElement("img");
const snowflake6 = document.createElement("img");
snowflake1.src = img1;
snowflake2.src = img2;
snowflake3.src = img3;
snowflake4.src = img4;
snowflake5.src = img5;
snowflake6.src = img6;

const images = [
  snowflake1,
  snowflake2,
  snowflake3,
  snowflake4,
  snowflake5,
  snowflake6,
];
// watch up
// snow fall

const PrivateRoute = lazy(() => import("./PrivateRoute"));
const PublicRoute = lazy(() => import("./PublicRoute"));

const App = () => {
  history.listen((location, action) => {
    // window.scrollTo(0, 0);
    window.scrollTo({ top: 0, behavior: "smooth" });
  });
  return (
    <BrowserRouter history={history} key={0}>
      {/* <ScrollIntoView> */}
      {/* <Snowfall
        // Changes the snowflake color
        color="red"
        // Applied to the canvas element
        style={{ position: "fixed", zIndex: 5000000000 }}
        // Controls the number of snowflakes that are created (default 150)
        snowflakeCount={15}
        speed={[0.5, 1]}
        wind={[0, 1.5]}
        rotationSpeed={[1.5, 3]}
        images={images}
        radius={[8, 15]}
      /> */}
      <Suspense fallback={<Loading />}>
        <Switch>
          <PublicRoute
            restricted={false}
            component={Home}
            path="/"
            exact
            key={1}
          />

          <PublicRoute
            restricted={true}
            component={SignIn}
            path="/signin"
            exact
            key={2}
          />

          <PrivateRoute
            restricted={true}
            component={Cart}
            path="/cart"
            exact
            key={3}
          />
          <PrivateRoute
            restricted={true}
            component={Saved}
            path="/saved"
            exact
            key={4}
          />

          <PublicRoute
            restricted={false}
            component={Category}
            path={"/category/:catId"}
            exact
            key={5}
          />
          <PublicRoute
            restricted={false}
            component={test}
            path={"/test"}
            exact
            key={6}
          />

          <PublicRoute
            restricted={false}
            component={Smartcategory}
            path={"/smart/:id"}
            exact
            key={7}
          />

          <PublicRoute
            restricted={false}
            component={ProductPage}
            path={"/product/:c_id/:p_id"}
            exact
            key={8}
          />

          <PublicRoute
            restricted={false}
            component={Subcategory}
            path={"/category/:cat_id/:catId"}
            exact
            key={9}
          />

          <PublicRoute
            restricted={false}
            component={NewProducts}
            path={"/newproducts"}
            exact
            key={10}
          />

          <PublicRoute
            restricted={false}
            component={Sale}
            path={"/saleproducts"}
            exact
            key={11}
          />

          <PublicRoute
            restricted={false}
            component={Search}
            path={"/search"}
            exact
            key={12}
          />
          <PrivateRoute
            restricted={true}
            component={_History}
            path="/history"
            exact
            key={13}
          />
          <PrivateRoute
            restricted={true}
            component={Profile}
            path="/profile"
            exact
            key={14}
          />

          <PublicRoute
            restricted={false}
            component={Category}
            path="/category"
            exact
            key={15}
          />

          <PublicRoute
            restricted={false}
            component={AllBrands}
            path="/allbrands"
            exact
            key={16}
          />

          <PublicRoute
            restricted={false}
            component={BrandProducts}
            path={"/brand_products/:id"}
            exact
            key={17}
          />

          <PublicRoute
            restricted={false}
            component={Payment}
            path={"/payment"}
            exact
            key={18}
          />
          <PublicRoute
            restricted={false}
            component={Privacy}
            path={"/privacy"}
            exact
            key={19}
          />
          <PublicRoute
            restricted={false}
            component={Terms}
            path={"/terms"}
            exact
            key={20}
          />

          <PublicRoute
            restricted={false}
            component={Ordering}
            path={"/order"}
            exact
            key={21}
          />

          <PublicRoute restricted={false} component={Faq} path={"/faq"} exact />

          <PublicRoute
            restricted={false}
            component={Partnership}
            path={"/partnership"}
            exact
            key={22}
          />

          <PublicRoute
            restricted={false}
            component={AboutUs}
            path={"/aboutus"}
            exact
            key={23}
          />

          <PublicRoute
            restricted={false}
            component={Contact}
            path={"/contactus"}
            exact
            key={24}
          />

          <Route key={25} path="/links" component={Appdownload} />

          <Route key={26} path="*" component={NotFound} />
        </Switch>
      </Suspense>
      {/* </ScrollIntoView> */}
    </BrowserRouter>
  );
};

export default App;

import React, { useState, useEffect, useContext } from "react";
import { Languages } from "../../lang/translation";
import { ProfileContext } from "../../contexts/LangContext";
import { Link } from "react-router-dom";
import Error from "../../components/error";
import { Layout, Col, Row } from "antd";
import { RightOutlined, LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import Sidebar from "../../components/sidebar";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { BASE_URL, Server } from "../../utils/server";
import { Card } from "../card/card";
import Down from "../../img/icons/down.svg";
import ImageWebp from "../../components/ImageWebp";
import useBanners from "../../hooks/useBanners";
import useSmallBanners from "../../hooks/useSmallBanners";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import "./banner.css";
import "./style.css";
const { Sider, Content } = Layout;

const Home = () => {
  const [product, setProduct] = useState([]);
  const [product_new, setNew] = useState([]);
  const [product_sale, setSale] = useState([]);
  const { language } = useContext(ProfileContext);

  const [limit, setLimit] = useState(7);
  const [offset, setOffset] = useState(0);
  const [size, setSize] = useState(0);
  const [load, setLoad] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [bannerLoad, setBannerLoad] = useState(false);
  const [error, setError] = useState(false);
  const {
    isLoading: banner_loading,
    error: banner_error,
    data: banners,
    refetch: BannersRefetch,
  } = useBanners();

  const {
    isLoading: small_loading,
    error: small_error,
    data: smallBanners,
    refetch: SmallRefetch,
  } = useSmallBanners();

  useEffect(() => {
    loadProducts();
  }, []);

  const loadProducts = () => {
    setError(false);
    setLoad(true);
    setLoadData(true);
    axios
      .get(BASE_URL + "/api/home", {
        params: {
          limit: limit,
          offset: offset,
          cardSize: window.innerWidth > 768 ? 5 : 4,
        },
      })
      .then((res) => {
        setProduct(res.data.data.rows);
        setNew(res.data.newProducts);
        setSale(res.data.saleProducts);
        setSize(res.data.data.count);
        setLoad(false);
        setLoadData(false);
      })
      .catch((err) => setError(true));
  };

  const loadMore = () => {
    let ofset = offset + limit;
    setLoad(true);
    axios
      .get(BASE_URL + "/api/home", {
        params: {
          cardSize: window.innerWidth > 768 ? 5 : 4,
          limit: limit,
          offset: ofset,
        },
      })
      .then((res) => {
        setProduct([...product, ...res.data.data.rows]);
        setOffset(ofset);
        setLoad(false);
        // window.scrollBy(0, -document.documentElement.offsetHeight / 1.8);
        window.dispatchEvent(new Event("resize"));
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Layout className="h-container">
        <Sider className="r-none sider" width={220} style={{ marginRight: 20 }}>
          <Sidebar />
        </Sider>

        <Content className="h-content">
          <div
            className="banner-flex"
            style={
              banner_loading
                ? {
                    backgroundColor: "#fff",
                    maxHeight: 280,
                    minHeight: 130,
                    borderRadius: 8,
                  }
                : null
            }
          >
            <div
              className="banner-flex-item1"
              style={banner_loading ? { display: "none" } : null}
            >
              <Splide
                options={{
                  type: "loop",
                  lazyLoad: true,
                  autoplay: true,
                  interval: 3000,
                  speed: 700,
                  width: "100%",
                  height: "auto",
                  classes: {
                    arrows: "splide__arrows slide-arrows",
                    arrow: "splide__arrow slide-arrow",
                    prev: "splide__arrow--prev slide-prev",
                    next: "splide__arrow--next slide-next",
                    // Add classes for pagination.
                    pagination: "splide__pagination slide-pagination", // container
                    page: "splide__pagination__page slide-page", // each button
                  },
                }}
              >
                {!banner_loading && !banner_error
                  ? banners.map((row, index) =>
                      Number(row.b_active) === 1 ? (
                        <SplideSlide key={index} className="banner-item">
                          <Link to={row.b_link}>
                            <ImageWebp
                              alt="Banner"
                              className="banner"
                              srcWebp={
                                language === "tm"
                                  ? BASE_URL + "/img/banners/" + row.b_img_tm
                                  : language === "en"
                                  ? BASE_URL + "/img/banners/" + row.b_img_en
                                  : language === "ru"
                                  ? BASE_URL + "/img/banners/" + row.b_img_ru
                                  : BASE_URL + "/img/banners/" + row.b_img_en
                              }
                              src={
                                row.b_img_tm
                                  ? language === "tm"
                                    ? BASE_URL +
                                      "/img/banners/" +
                                      row.b_img_tm.substr(
                                        0,
                                        row.b_img_tm.lastIndexOf(".")
                                      ) +
                                      ".jpg"
                                    : language === "en"
                                    ? BASE_URL +
                                      "/img/banners/" +
                                      row.b_img_en.substr(
                                        0,
                                        row.b_img_en.lastIndexOf(".")
                                      ) +
                                      ".jpg"
                                    : language === "ru"
                                    ? BASE_URL +
                                      "/img/banners/" +
                                      row.b_img_ru.substr(
                                        0,
                                        row.b_img_ru.lastIndexOf(".")
                                      ) +
                                      ".jpg"
                                    : BASE_URL +
                                      "/img/banners/" +
                                      row.b_img_en.substr(
                                        0,
                                        row.b_img_en.lastIndexOf(".")
                                      ) +
                                      ".jpg"
                                  : null
                              }
                              // onLoad={() => setBannerLoad(true)}
                            />
                          </Link>
                        </SplideSlide>
                      ) : null
                    )
                  : null}
              </Splide>
            </div>

            <div className="banner-flex-item2">
              {!small_loading && !small_error
                ? smallBanners.map((row, index) => {
                    return (
                      <a key={index} href={row.bs_link} target="_blank">
                        <ImageWebp
                          hidden={!bannerLoad}
                          className="smallbanner"
                          alt="banner"
                          srcWebp={
                            language === "tm"
                              ? BASE_URL + "/img/banners/" + row.bs_img_tm
                              : language === "en"
                              ? BASE_URL + "/img/banners/" + row.bs_img_en
                              : language === "ru"
                              ? BASE_URL + "/img/banners/" + row.bs_img_ru
                              : BASE_URL + "/img/banners/" + row.bs_img_en
                          }
                          src={
                            row.bs_img_tm
                              ? language === "tm"
                                ? BASE_URL +
                                  "/img/banners/" +
                                  row.bs_img_tm.substr(
                                    0,
                                    row.bs_img_tm.lastIndexOf(".")
                                  ) +
                                  ".jpg"
                                : language === "en"
                                ? BASE_URL +
                                  "/img/banners/" +
                                  row.bs_img_en.substr(
                                    0,
                                    row.bs_img_en.lastIndexOf(".")
                                  ) +
                                  ".jpg"
                                : language === "ru"
                                ? BASE_URL +
                                  "/img/banners/" +
                                  row.bs_img_ru.substr(
                                    0,
                                    row.bs_img_ru.lastIndexOf(".")
                                  ) +
                                  ".jpg"
                                : BASE_URL +
                                  "/img/banners/" +
                                  row.bs_img_en.substr(
                                    0,
                                    row.bs_img_en.lastIndexOf(".")
                                  ) +
                                  ".jpg"
                              : null
                          }
                          onLoad={() => setBannerLoad(true)}
                        />
                        {/* </Link> */}
                      </a>
                    );
                  })
                : null}
            </div>
          </div>

          {!loadData ? (
            <>
              {product_new.length > 0 && (
                <div className="home-menu-cat">
                  <div className="category-name">
                    {Languages[language].productnew}
                  </div>
                  <Link to="/newproducts">
                    <div className="view">
                      <div className="view-text">
                        {Languages[language].view}
                        <RightOutlined
                          style={{ fontSize: 14, marginLeft: 5 }}
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              )}
              <Row
                style={{ marginTop: 2 }}
                className="card-container"
                type="flex"
                gutter={[6, 6]}
              >
                {product_new.length > 0 &&
                  product_new.map((r, index) => (
                    <Col
                      key={index}
                      xs={12}
                      sm={8}
                      md={6}
                      lg={4}
                      className="grid-5"
                    >
                      <Card key={index} row={r} />
                    </Col>
                  ))}
              </Row>
              {product_sale.length > 0 ? (
                <>
                  <div className="home-menu-cat">
                    <div className="category-name">
                      {Languages[language].productsale}
                    </div>
                    <Link to="/saleproducts">
                      <div className="view">
                        <div className="view-text">
                          {Languages[language].view}
                          <RightOutlined
                            style={{ fontSize: 14, marginLeft: 5 }}
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                  <Row
                    style={{ marginTop: 2 }}
                    className="card-container"
                    type="flex"
                    gutter={[6, 6]}
                  >
                    {product_sale.map((r, index) => (
                      <Col
                        key={index}
                        xs={12}
                        sm={8}
                        md={6}
                        lg={4}
                        className="grid-5"
                      >
                        <Card key={index} row={r} />
                      </Col>
                    ))}
                  </Row>
                </>
              ) : null}
            </>
          ) : null}

          {product.length > 0 &&
            product.map((row, index) => {
              return (
                <React.Fragment key={index}>
                  {row.products.length > 0 && (
                    <div className="home-menu-cat" >
                      <div className="category-name">
                        {language === "en"
                          ? row.k_name_en
                          : language === "ru"
                          ? row.k_name_ru
                          : language === "tm"
                          ? row.k_name_tm
                          : row.k_name_en}
                      </div>
                      <Link
                        to={
                          "/category/" +
                          row.k_name_en.split(" ").join("-").toLowerCase() +
                          "-" +
                          row.k_id
                        }
                      >
                        <div className="view">
                          <div className="view-text">
                            {Languages[language].view}
                            <RightOutlined
                              style={{ fontSize: 14, marginLeft: 5 }}
                            />
                          </div>
                        </div>
                      </Link>
                    </div>
                  )}
                  <Row
                    style={{ marginTop: 2 }}
                    className="card-container"
                    type="flex"
                    gutter={[6, 6]}
                  >
                    {row.products.map((r, index) => (
                      <Col
                        key={index}
                        xs={12}
                        sm={8}
                        md={6}
                        lg={4}
                        className="grid-5"
                      >
                        <Card key={index} row={r} />
                      </Col>
                    ))}
                  </Row>
                </React.Fragment>
              );
            })}

          <div className="load-more-layout">
            {load ? (
              <LoadingOutlined className="load-more" spin />
            ) : size !== product.length ? (
              <button
                type="primary"
                className="more-btn"
                onClick={() => loadMore()}
              >
                {Languages[language].more} <img src={Down} alt="down" />
              </button>
            ) : null}
          </div>
        </Content>
      </Layout>
      <br />
    </div>
  );
};

export default Home;
